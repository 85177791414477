import React, { useState, useEffect } from "react";
import { db } from "../../config/firebaseConfig"; // Firebase 설정 import
import { ref, onValue, push } from "firebase/database"; // Firebase 실시간 데이터베이스 관련 메서드
import { useAuth } from "../../contexts/AuthContext"; // 사용자 인증 정보를 가져오기 위한 AuthContext
import { Send, MessageCircle } from "lucide-react"; // 채팅 이모티콘 추가
import { ChevronUp, ChevronDown } from "lucide-react"; // 채팅 창 토글 아이콘

const ChatBox = () => {
  const [isOpen, setIsOpen] = useState(false); // 챗박스 열림/닫힘 상태
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const { currentUser } = useAuth(); // 현재 사용자 정보를 가져오기 위한 AuthContext

  useEffect(() => {
    if (isOpen) {
      // Firebase 실시간 데이터베이스에서 메시지 목록 가져오기
      const chatRef = ref(db, "chats/");
      const unsubscribe = onValue(chatRef, (snapshot) => {
        const data = snapshot.val();
        const loadedMessages = data
          ? Object.keys(data).map((key) => ({
              id: key,
              ...data[key],
            }))
          : [];
        setMessages(loadedMessages);
      });

      // 컴포넌트가 언마운트될 때 Firebase 리스너 해제
      return () => unsubscribe();
    }
  }, [isOpen]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    const messageData = {
      text: newMessage,
      user: currentUser.email, // 현재 사용자의 이메일
      timestamp: Date.now(),
    };

    // Firebase에 메시지 추가
    await push(ref(db, "chats/"), messageData);
    setNewMessage("");
  };

  return (
    <div className="fixed bottom-0 right-4 w-full max-w-xs sm:max-w-sm">
      {/* 챗박스 헤더 (닫혀있을 때 버튼으로 표시) */}
      <div
        className="flex justify-between items-center bg-black text-white p-2 rounded-t-lg cursor-pointer border border-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <img
            src="https://via.placeholder.com/40"
            alt="Manager Avatar"
            className="w-8 h-8 rounded-full mr-2"
          />
          <span>Support Chat</span>
          {/* 채팅 이모티콘 추가 */}
          <MessageCircle className="ml-2 w-5 h-5" />
        </div>
        {isOpen ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
      </div>

      {/* 챗박스 본체 (열려있을 때만 표시) */}
      {isOpen && (
        <div
          className="bg-white rounded-b-lg shadow-lg p-4"
          style={{ height: "400px" }}
        >
          {/* LIVE 표시 */}
          <div className="flex justify-between items-center mb-2">
            <span className="text-xs text-gray-500">Manager Amanda</span>
            <span className="text-xs text-green-500">LIVE</span>
          </div>

          {/* 메시지 표시 영역 */}
          <div className="overflow-y-auto h-56 mb-4">
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`mb-2 ${
                  msg.user === currentUser.email ? "text-right" : "text-left"
                }`}
              >
                <div
                  className={`inline-block rounded-lg p-2 ${
                    msg.user === currentUser.email
                      ? "bg-black text-white"
                      : "bg-gray-200 text-gray-800"
                  }`}
                >
                  {msg.text}
                </div>
                <div className="text-xs text-gray-500">
                  {msg.user === currentUser.email ? "You" : "Amanda"} ·{" "}
                  {new Date(msg.timestamp).toLocaleTimeString()}
                </div>
              </div>
            ))}
          </div>

          {/* 메시지 입력 및 전송 버튼 */}
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              className="border border-gray-300 rounded-full w-full py-2 px-4"
              placeholder="Type here..."
            />
            <button
              onClick={handleSendMessage}
              className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600"
            >
              <Send className="inline-block h-5 w-5" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
