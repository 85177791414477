import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

const areaChartOptions = (yAxisInterval, xAxisInterval) => ({
  chart: {
    height: 450,
    type: "area",
    toolbar: {
      show: true,
      tools: {
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  grid: {
    strokeDashArray: 0,
  },
  yaxis: {
    tickAmount: yAxisInterval ? 450 / yAxisInterval : undefined,
    labels: {
      formatter: (val) => Math.round(val),
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeFormatter: {
        year: "yyyy",
        month: "MMM yyyy",
        day: "dd MMM",
        hour: "HH:mm",
      },
      show: true,
      rotate: -45,
      maxHeight: 100,
    },
    tickAmount: Math.floor(1440 / xAxisInterval), // 1440 분 = 24 시간 * 60 분
  },
});

const filterDataByInterval = (data, interval) => {
  const filteredBalanceData = [];
  const filteredEquityData = [];

  Object.keys(data).forEach((date) => {
    Object.keys(data[date]).forEach((time, index) => {
      if (index % interval === 0) {
        const timeData = data[date][time];
        const [year, month, day] = date.split("_").map(Number);
        const [hour, minute] = time.split("_").map(Number);
        const dateTime = Date.UTC(year, month - 1, day, hour, minute); // UTC 기준 타임스탬프 생성
        filteredBalanceData.push([dateTime, parseFloat(timeData.Balance)]);
        filteredEquityData.push([dateTime, parseFloat(timeData.Equity)]);
      }
    });
  });

  return { filteredBalanceData, filteredEquityData };
};

const IncomeAreaChart = ({ data, yAxisInterval, xAxisInterval }) => {
  const [options, setOptions] = useState(
    areaChartOptions(yAxisInterval, xAxisInterval)
  );
  const [series, setSeries] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (Object.keys(data).length === 0) {
      setIsDataLoaded(false);
    } else {
      const { filteredBalanceData, filteredEquityData } = filterDataByInterval(
        data,
        xAxisInterval
      );
      setOptions(areaChartOptions(yAxisInterval, xAxisInterval));
      setSeries([
        {
          name: "Balance",
          data: filteredBalanceData,
        },
        {
          name: "Equity",
          data: filteredEquityData,
        },
      ]);
      setIsDataLoaded(true);
    }
  }, [data, yAxisInterval, xAxisInterval]);

  if (!isDataLoaded) {
    return (
      <div className="flex justify-center items-center h-[450px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="w-full h-[450px]">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={450}
      />
    </div>
  );
};

// PropTypes 정의
IncomeAreaChart.propTypes = {
  data: PropTypes.object.isRequired,
  yAxisInterval: PropTypes.number,
  xAxisInterval: PropTypes.number,
};

export default IncomeAreaChart;
