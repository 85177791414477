import React from "react";
import { useAuth } from "../contexts/AuthContext"; // AuthContext import 추가
import WelcomeSection from "../components/dashboard/WelcomeSection";
import MarketingBoard from "../components/dashboard/MarketingBoard";
import Banner from "../components/dashboard/Banner";
import CardNews from "../components/dashboard/CardNews";
import AccountList from "../components/dashboard/AccountList";
import TvCard from "../components/dashboard/TvCard";
import BrokerCard2 from "../components/dashboard/BrokerCard2";
import ChatBox from "../components/dashboard/chatbox";

const Dashboard = () => {
  const { currentUser } = useAuth(); // 현재 사용자 상태 가져오기

  return (
    <div className="w-full max-w-full overflow-x-hidden">
      <div className="px-2 sm:px-4 md:px-6 lg:px-8 space-y-4 py-4">
        {/* Mobile layout */}
        <div className="lg:hidden space-y-4">
          <WelcomeSection />
          <div className="overflow-x-auto -mx-2 px-2">
            <MarketingBoard />
          </div>
          <AccountList />
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <CardNews />
            <BrokerCard2 />
          </div>
          <TvCard />
          <Banner />
        </div>
        {/* Desktop layout - 변경 없음 */}
        <div className="hidden lg:grid lg:grid-cols-3 lg:gap-4">
          <div className="lg:col-span-2 space-y-4">
            <WelcomeSection />
            <MarketingBoard />
            {currentUser && <AccountList />}
          </div>
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <CardNews />
              <BrokerCard2 />
            </div>
            <div>
              <TvCard />
              <Banner />
            </div>
          </div>
        </div>

        {/* 실시간 채팅 기능 */}
        {currentUser && <ChatBox />}
      </div>
    </div>
  );
};

export default Dashboard;
