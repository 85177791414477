import React from "react";
import NewsItem from "./NewsItem";

const Timeline = ({ data }) => {
  let lastDate = null;

  const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    return date.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  };

  const formatTime = (timestamp) => {
    const time = new Date(parseInt(timestamp));
    return time.toLocaleTimeString("ko-KR", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="timeline space-y-8">
      {data.map((item, index) => {
        const itemDate = formatDate(item.id);
        const itemTime = formatTime(item.id);

        // 날짜가 달라질 때마다 새로운 날짜 구분선을 추가
        const showDate = lastDate !== itemDate;
        lastDate = itemDate;

        return (
          <div key={index}>
            {showDate && (
              <div className="date-divider text-gray-500 text-center my-4">
                {itemDate}
              </div>
            )}
            <NewsItem
              title={item.title}
              content={item.content}
              time={itemTime}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
