import React, { forwardRef } from "react";
import Highlighter from "./third-party/Highlighter";

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClassName = "",
      darkTitle,
      secondary,
      shadow,
      className = "",
      title,
      codeHighlight,
      ...others
    },
    ref
  ) => {
    const cardClassName = `
      rounded-lg
      ${border ? "border border-gray-200" : ""}
      ${boxShadow ? "shadow-md hover:shadow-lg" : ""}
      ${className}
    `;

    const headerClassName = "p-4 flex justify-between items-center";
    const titleClassName = darkTitle
      ? "text-xl font-bold"
      : "text-lg font-semibold";

    return (
      <div ref={ref} className={cardClassName} {...others}>
        {/* card header and action */}
        {title && (
          <div className={headerClassName}>
            <h3 className={titleClassName}>{title}</h3>
            {secondary && <div>{secondary}</div>}
          </div>
        )}

        {/* card content */}
        {content && <div className={`p-4 ${contentClassName}`}>{children}</div>}
        {!content && children}

        {/* card footer - clipboard & highlighter  */}
        {codeHighlight && (
          <>
            <hr className="border-dashed" />
            <Highlighter codeHighlight={codeHighlight} main>
              {children}
            </Highlighter>
          </>
        )}
      </div>
    );
  }
);

export default MainCard;
