import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainCard from "../components/MainCard";
import BlogCard from "../components/BlogCard";
import BrokerCard from "../components/BrokerCard";
import ContactCard from "../components/ContactCard";
import "../styles/HomePage.css";

const HomePage = () => {
  const [isExiting, setIsExiting] = useState(false);
  const navigate = useNavigate();

  const handleExitStart = () => {
    setIsExiting(true);
    setTimeout(() => {
      navigate("/dashboard");
    }, 1000);
  };

  return (
    <div className="flex justify-center items-center h-full">
      <div
        className={`flex flex-col h-full w-full max-w-[1920px] max-h-[1080px] transition-opacity duration-1000 ${
          isExiting ? "opacity-0" : "opacity-100"
        }`}
      >
        {/* Mobile layout */}
        <div className="lg:hidden flex flex-col space-y-4 overflow-auto h-full">
          <div className="card-container mobile-main-card">
            <MainCard onExitStart={handleExitStart} />
          </div>
          <div className="card-container mobile-blog-card">
            <BlogCard />
          </div>
          <div className="card-container mobile-broker-card">
            <BrokerCard />
          </div>
          <div className="card-container mobile-contact-card">
            <ContactCard />
          </div>
        </div>

        {/* Desktop layout */}
        <div className="hidden lg:flex flex-grow overflow-hidden">
          <div className="w-1/2 p-2 overflow-hidden">
            <div className="h-full">
              <MainCard onExitStart={handleExitStart} />
            </div>
          </div>
          <div className="w-1/2 flex flex-col p-2 overflow-hidden">
            <div className="h-1/2 pb-2">
              <BlogCard />
            </div>
            <div className="h-1/2 pt-2 flex">
              <div className="w-1/2 pr-2">
                <BrokerCard />
              </div>
              <div className="w-1/2 pl-2">
                <ContactCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
