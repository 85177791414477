import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Link,
} from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import HomePage from "./pages/HomePage";
import Dashboard from "./pages/Dashboard";
import AccountDetailPage from "./components/dashboard/detail/AccountDetailPage";
import NavigationBar from "./components/NavigationBar";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import UserProfile from "./components/auth/UserProfile";
import NewsPage from "./pages/news";
import Broker from "./pages/Broker"; // Broker 컴포넌트 import
import Loading from "./components/Loading";

const TopNavigation = () => {
  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-xl font-bold">
                ALL THAT FX
              </Link>
            </div>
          </div>
          <div className="flex items-center">
            <Link
              to="/login"
              className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
            >
              Login
            </Link>
            <Link
              to="/register"
              className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
            >
              Register
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

const Layout = ({ children, isLoading }) => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const location = useLocation();

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const isHomePage = location.pathname === "/";
  const isDashboard = location.pathname.startsWith("/dashboard");
  const isNewsPage = location.pathname === "/news";

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen flex flex-col overflow-x-hidden">
      {isHomePage && <TopNavigation />}
      <div className="flex flex-grow overflow-x-hidden">
        {(isDashboard || isNewsPage) && (
          <NavigationBar isVisible={isNavVisible} toggleNav={toggleNav} />
        )}
        <div
          className={`flex-grow transition-all duration-300 pt-0 lg:pt-8 overflow-x-hidden ${
            isDashboard || isNewsPage
              ? isNavVisible
                ? "ml-0 lg:ml-52"
                : "ml-0 lg:ml-32"
              : "ml-0"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

const AnimatedSwitch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    } else {
      setIsLoading(false);
    }
  }, [location]);

  return (
    <Layout isLoading={isLoading}>
      {!isLoading && (
        <Routes location={location}>
          <Route path="/" element={<HomePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/:nodeId" element={<AccountDetailPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/broker" element={<Broker />} />{" "}
          {/* Broker 라우트 추가 */}
        </Routes>
      )}
    </Layout>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AnimatedSwitch />
      </Router>
    </AuthProvider>
  );
}

export default App;
