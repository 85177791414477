import React from "react";
import loadingGif from "../assets/loading.gif";

const Loading = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black">
      <img src={loadingGif} alt="Loading..." className="w-64 h-64" />{" "}
      {/* 크기 조정 */}
    </div>
  );
};

export default Loading;
