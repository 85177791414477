import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { db } from "../../../config/firebaseConfig";
import { ref, get } from "firebase/database";

// Chart.js 요소와 플러그인 등록
Chart.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement
);

const PieChartComponent = () => {
  const { nodeId } = useParams();
  const [overallWinRate, setOverallWinRate] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#B4E197",
    "#FF6961",
    "#779ECB",
    "#FFB347",
    "#B39EB5",
    "#77DD77",
  ];
  const lightSalmon = "#FFA07A";

  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "종목별 거래횟수",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  });

  const [buySellChartData, setBuySellChartData] = useState({
    labels: ["매수", "매도"],
    datasets: [
      {
        label: "수익",
        data: [],
        backgroundColor: "#4CAF50",
        borderColor: "#4CAF50",
        borderWidth: 1,
      },
      {
        label: "손실",
        data: [],
        backgroundColor: "#F44336",
        borderColor: "#F44336",
        borderWidth: 1,
      },
    ],
  });

  const [profitLossChartData, setProfitLossChartData] = useState({
    labels: ["수익", "손실"],
    datasets: [
      {
        label: "수익/손실 비율",
        data: [],
        backgroundColor: ["#4CAF50", "#F44336"],
        borderColor: ["#4CAF50", "#F44336"],
        borderWidth: 1,
      },
    ],
  });

  const [dailyLotChartData, setDailyLotChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Daily Lot",
        data: [],
        backgroundColor: lightSalmon,
        borderColor: lightSalmon,
        borderWidth: 1,
      },
    ],
  });

  const [monthlyLotChartData, setMonthlyLotChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Monthly Lot",
        data: [],
        backgroundColor: lightSalmon,
        borderColor: lightSalmon,
        borderWidth: 1,
      },
    ],
  });

  const [itemLotChartData, setItemLotChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "매수 Lot",
        data: [],
        backgroundColor: "#4CAF50",
        borderColor: "#4CAF50",
        borderWidth: 1,
      },
      {
        label: "매도 Lot",
        data: [],
        backgroundColor: "#F44336",
        borderColor: "#F44336",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itemDetailsRef = ref(
          db,
          `mt4data/${nodeId}/analysis/ItemDetails`
        );
        const buyTradesRef = ref(db, `mt4data/${nodeId}/analysis/BuyCount`);
        const sellTradesRef = ref(db, `mt4data/${nodeId}/analysis/SellCount`);
        const profitTradesRef = ref(db, `mt4data/${nodeId}/analysis/WinCount`);
        const lossTradesRef = ref(db, `mt4data/${nodeId}/analysis/LoseCount`);
        const buyProfitTradesRef = ref(
          db,
          `mt4data/${nodeId}/analysis/BuyWinCount`
        );
        const sellProfitTradesRef = ref(
          db,
          `mt4data/${nodeId}/analysis/SellWinCount`
        );
        const overallWinRateRef = ref(
          db,
          `mt4data/${nodeId}/analysis/OverallWinRate`
        );
        const dailyLotCountRef = ref(
          db,
          `mt4data/${nodeId}/analysis/DailyLotCount`
        );
        const monthlyLotCountRef = ref(
          db,
          `mt4data/${nodeId}/analysis/MonthlyLotCount`
        );

        const [
          itemDetailsSnapshot,
          buyTradesSnapshot,
          sellTradesSnapshot,
          profitTradesSnapshot,
          lossTradesSnapshot,
          buyProfitTradesSnapshot,
          sellProfitTradesSnapshot,
          overallWinRateSnapshot,
          dailyLotCountSnapshot,
          monthlyLotCountSnapshot,
        ] = await Promise.all([
          get(itemDetailsRef),
          get(buyTradesRef),
          get(sellTradesRef),
          get(profitTradesRef),
          get(lossTradesRef),
          get(buyProfitTradesRef),
          get(sellProfitTradesRef),
          get(overallWinRateRef),
          get(dailyLotCountRef),
          get(monthlyLotCountRef),
        ]);

        const itemDetails = itemDetailsSnapshot.val() || {};
        const buyTrades = buyTradesSnapshot.val() || 0;
        const sellTrades = sellTradesSnapshot.val() || 0;
        const profitTrades = profitTradesSnapshot.val() || 0;
        const lossTrades = lossTradesSnapshot.val() || 0;
        const buyProfitTrades = buyProfitTradesSnapshot.val() || 0;
        const sellProfitTrades = sellProfitTradesSnapshot.val() || 0;
        const buyLossTrades = buyTrades - buyProfitTrades;
        const sellLossTrades = sellTrades - sellProfitTrades;
        const overallWinRate = overallWinRateSnapshot.val() || 0;

        const labels = Object.keys(itemDetails);
        const data = Object.values(itemDetails).map((item) => item.TotalTrades);

        setBarChartData({
          labels,
          datasets: [
            {
              label: "종목별 거래횟수",
              data,
              backgroundColor: colors.slice(0, labels.length),
              borderColor: colors.slice(0, labels.length),
              borderWidth: 1,
            },
          ],
        });

        setBuySellChartData({
          labels: ["매수", "매도"],
          datasets: [
            {
              label: "수익",
              data: [buyProfitTrades, sellProfitTrades],
              backgroundColor: "#4CAF50",
              borderColor: "#4CAF50",
              borderWidth: 1,
            },
            {
              label: "손실",
              data: [buyLossTrades, sellLossTrades],
              backgroundColor: "#F44336",
              borderColor: "#F44336",
              borderWidth: 1,
            },
          ],
        });

        setProfitLossChartData({
          labels: ["수익", "손실"],
          datasets: [
            {
              label: "수익/손실 비율",
              data: [profitTrades, lossTrades],
              backgroundColor: ["#4CAF50", "#F44336"],
              borderColor: ["#4CAF50", "#F44336"],
              borderWidth: 1,
            },
          ],
        });

        setOverallWinRate(overallWinRate);

        // Daily Lot 차트 데이터 설정
        const dailyLotCount = dailyLotCountSnapshot.val() || {};
        const dailyKeys = Object.keys(dailyLotCount).slice(-7);
        const dailyLabels = dailyKeys.map((key) => key.replace(/_/g, "-"));
        const dailyData = dailyKeys.map((key) => dailyLotCount[key]);

        setDailyLotChartData({
          labels: dailyLabels,
          datasets: [
            {
              label: "Daily Lot",
              data: dailyData,
              backgroundColor: lightSalmon,
              borderColor: lightSalmon,
              borderWidth: 1,
            },
          ],
        });

        // Monthly Lot 차트 데이터 설정
        const monthlyLotCount = monthlyLotCountSnapshot.val() || {};
        const monthlyKeys = Object.keys(monthlyLotCount).slice(-3);
        const monthlyLabels = monthlyKeys.map((key) => key.replace(/_/g, "-"));
        const monthlyData = monthlyKeys.map((key) => monthlyLotCount[key]);

        setMonthlyLotChartData({
          labels: monthlyLabels,
          datasets: [
            {
              label: "Monthly Lot",
              data: monthlyData,
              backgroundColor: lightSalmon,
              borderColor: lightSalmon,
              borderWidth: 1,
            },
          ],
        });

        // 종목별 Lot 차트 데이터 설정
        const itemLotLabels = Object.keys(itemDetails);
        const itemBuyLotData = itemLotLabels.map((label) =>
          parseFloat((itemDetails[label].BuyLots || 0).toFixed(2))
        );
        const itemSellLotData = itemLotLabels.map((label) =>
          parseFloat((itemDetails[label].SellLots || 0).toFixed(2))
        );

        setItemLotChartData({
          labels: itemLotLabels,
          datasets: [
            {
              label: "매수 Lot",
              data: itemBuyLotData,
              backgroundColor: "#4CAF50",
              borderColor: "#4CAF50",
              borderWidth: 1,
            },
            {
              label: "매도 Lot",
              data: itemSellLotData,
              backgroundColor: "#F44336",
              borderColor: "#F44336",
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [nodeId]);

  const handleTabChange = (newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <div className="space-y-4">
      <div className="w-full h-96">
        <Bar
          data={barChartData}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col items-center justify-center">
          <div className="w-64 h-64">
            <Doughnut
              data={profitLossChartData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
          <p className="mt-4 text-lg font-bold">
            거래 승률: {Math.round(overallWinRate)}%
          </p>
        </div>
        <div className="w-full h-96">
          <Bar
            data={buySellChartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              indexAxis: "y",
              scales: {
                x: {
                  stacked: true,
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "거래 횟수",
                  },
                },
                y: {
                  stacked: true,
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "거래 종류",
                  },
                },
              },
            }}
          />
        </div>
      </div>
      <div className="flex justify-center space-x-4">
        {["Daily Lot", "Monthly Lot", "거래종목별 Lot"].map((label, index) => (
          <button
            key={label}
            className={`px-4 py-2 rounded ${
              tabIndex === index
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => handleTabChange(index)}
          >
            {label}
          </button>
        ))}
      </div>
      <div className="w-full h-96">
        {tabIndex === 0 && (
          <Bar
            data={dailyLotChartData}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
        )}
        {tabIndex === 1 && (
          <Bar
            data={monthlyLotChartData}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
        )}
        {tabIndex === 2 && (
          <Bar
            data={itemLotChartData}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
        )}
      </div>
    </div>
  );
};

export default PieChartComponent;
