import React from "react";
import { ChevronRight } from "lucide-react";
import mainImage from "../assets/main.png";

const MainCard = ({ onExitStart }) => {
  return (
    <div
      className="bg-gray-900 text-white p-6 md:p-8 rounded-3xl flex flex-col justify-between h-full cursor-pointer relative overflow-hidden"
      onClick={onExitStart}
    >
      {/* 이미지 컨테이너 */}
      <div className="absolute inset-0 z-0">
        <img
          src={mainImage}
          alt="Main offer background"
          className="w-full h-full object-cover"
          style={{ objectPosition: "50% 50%" }}
        />
      </div>

      {/* 반투명 오버레이 */}
      <div className="absolute inset-0 bg-gray-900 opacity-70 z-10"></div>

      {/* 콘텐츠 */}
      <div className="relative z-20 flex flex-col justify-between h-full">
        {/* 데스크톱 레이아웃 */}
        <div className="hidden lg:block">
          <h3 style={{ fontSize: "14px" }} className="mb-2 md:mb-4">
            MORE OFFERS
          </h3>

          <div className="text-white leading-snug">
            <h2 className="text-white mb-4">
              <span style={{ fontSize: "100px" }}>One</span>{" "}
              <span>&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;</span>
              <span style={{ fontSize: "75px" }}>dashboard,</span>
            </h2>
            <h2 className="text-white mb-4">
              <span style={{ fontSize: "100px" }}>All</span>{" "}
              <span>&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;</span>
              <span style={{ fontSize: "75px" }}>your FX trading data,</span>
            </h2>
            <h2 className="text-white mb-4">
              <span style={{ fontSize: "70px" }}>- supporting exchanges</span>{" "}
              <span>&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;</span>
              <span style={{ fontSize: "100px" }}>Worldwide</span>.
            </h2>
          </div>
        </div>

        {/* 모바일 레이아웃 */}
        <div className="lg:hidden flex flex-col justify-center items-center h-full">
          <h2 className="text-white text-4xl font-bold text-center">
            FX Dashboard
          </h2>
        </div>

        {/* 버튼 (데스크톱과 모바일 모두 표시) */}
        <div className="flex flex-col md:flex-row items-center md:items-end space-y-4 md:space-y-0 md:space-x-4">
          <button className="bg-white text-black px-8 py-2 md:px-10 md:py-5 rounded-full flex items-center text-lg md:text-xl font-semibold transition-all duration-300 ease-in-out shadow-lg hover:bg-gray-200 hover:shadow-xl">
            Click
            <ChevronRight className="ml-5 w-6 h-7 md:w-7 md:h-7" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainCard;
