import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import brokerImage from "../../assets/images/broker.png";

const BrokerCard = ({ imageUrl, name, description, features, link }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleMoreClick = (event) => {
    event.stopPropagation();
    navigate(link);
  };

  return (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
      style={{ width: "100%", aspectRatio: "1 / 1.4" }}
      onClick={handleClick}
    >
      <div className="relative h-full">
        <div
          className={`relative h-3/5 transition-all duration-300 ${
            isExpanded ? "h-1/3" : ""
          }`}
        >
          <img
            src={imageUrl}
            alt={name}
            className={`w-full h-full object-cover transition-all duration-300 ${
              isExpanded ? "filter blur-sm" : ""
            }`}
          />
          <div className="absolute top-2 right-2 bg-blue-500 text-white rounded-full w-12 h-12 flex items-center justify-center">
            <span className="text-xl">FX</span>
          </div>
          <div className="absolute bottom-0 left-0 bg-blue-500 text-white px-2 py-1 text-xs">
            BROKER
          </div>
        </div>
        <div
          className={`absolute bottom-0 left-0 right-0 bg-white transition-all duration-300 ${
            isExpanded ? "h-2/3" : "h-2/5"
          }`}
        >
          <div className="p-4 h-full flex flex-col">
            <h2 className="text-xl mb-2">{name}</h2>
            <p className="text-blue-500 mb-2">{description.split(".")[0]}.</p>
            <div
              className={`overflow-hidden transition-all duration-300 ${
                isExpanded ? "flex-grow" : "h-0"
              }`}
            >
              <p className="text-gray-600">{description}</p>
              <ul className="mt-2 list-disc list-inside">
                {features.map((feature, index) => (
                  <li key={index} className="text-sm">
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-auto text-sm text-gray-500 flex justify-between">
              <span>Established: 2005</span>
              <span>Rating: 4.5/5</span>
            </div>
            {isExpanded && (
              <div className="text-center mt-4">
                <button
                  onClick={handleMoreClick}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const BrokerContainer = () => {
  const brokerData = {
    imageUrl: brokerImage,
    name: "FX Global Broker",
    description:
      "FX Global Broker is a leading forex trading platform offering competitive spreads and advanced trading tools. With a strong focus on customer service and educational resources, we provide traders with the support they need to succeed in the forex market.",
    features: [
      "Low spreads from 0.1 pips",
      "High leverage up to 1:500",
      "Advanced trading platforms",
      "24/7 customer support",
      "Educational resources and webinars",
    ],
    link: "/broker", // More 버튼 클릭 시 이동할 페이지 경로
  };

  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-4 overflow-hidden">
      <BrokerCard {...brokerData} />
    </div>
  );
};

export default BrokerContainer;
