import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

const UserProfile = () => {
  const { currentUser, updateProfile } = useAuth();
  const [profile, setProfile] = useState({
    displayName: "",
    email: "",
    photoURL: "",
    // 필요한 경우 추가 필드
  });

  useEffect(() => {
    if (currentUser) {
      setProfile({
        displayName: currentUser.displayName || "",
        email: currentUser.email || "",
        photoURL: currentUser.photoURL || "",
        // 필요한 경우 추가 필드 초기화
      });
    }
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProfile({
        displayName: profile.displayName,
        photoURL: profile.photoURL,
        // 필요한 경우 추가 필드 업데이트
      });
      alert("프로필이 성공적으로 업데이트되었습니다.");
    } catch (error) {
      console.error("프로필 업데이트 중 오류 발생:", error);
      alert("프로필 업데이트에 실패했습니다.");
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-5 text-center">프로필 편집</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="displayName" className="block mb-2 font-medium">
            이름
          </label>
          <input
            type="text"
            id="displayName"
            name="displayName"
            value={profile.displayName}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label htmlFor="email" className="block mb-2 font-medium">
            이메일
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={profile.email}
            className="w-full px-3 py-2 border rounded-md bg-gray-100"
            disabled
          />
        </div>
        <div>
          <label htmlFor="photoURL" className="block mb-2 font-medium">
            프로필 사진 URL
          </label>
          <input
            type="url"
            id="photoURL"
            name="photoURL"
            value={profile.photoURL}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
        >
          프로필 업데이트
        </button>
      </form>
    </div>
  );
};

export default UserProfile;
