import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../config/firebaseConfig";
import { ref, get } from "firebase/database";

const GiftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
      clipRule="evenodd"
    />
    <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
  </svg>
);

const LogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
      clipRule="evenodd"
    />
  </svg>
);

const TransactionHistory = () => {
  const { nodeId } = useParams();
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyRef = ref(db, `mt4data/${nodeId}/info/Transactions`);
        const snapshot = await get(historyRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const historyArray = Object.values(data).map((entry) => ({
            date: entry.Date,
            type: entry.Type,
            amount: entry.Amount,
            description: entry.Description,
          }));

          historyArray.sort(
            (a, b) =>
              new Date(b.date.replace(/\./g, "-")) -
              new Date(a.date.replace(/\./g, "-"))
          );
          setHistoryData(historyArray);
        }
      } catch (error) {
        console.error("Error fetching history data:", error);
      }
    };

    if (nodeId) {
      fetchHistory();
    }
  }, [nodeId]);

  return (
    <ul className="divide-y divide-gray-200">
      {historyData.map((item, index) => (
        <li key={index} className="py-4">
          <div className="flex items-center space-x-4">
            <div
              className={`flex-shrink-0 w-10 h-10 rounded-full flex items-center justify-center ${
                item.type === "Deposit"
                  ? "bg-green-100 text-green-600"
                  : "bg-red-100 text-red-600"
              }`}
            >
              {item.type === "Deposit" ? <GiftIcon /> : <LogoutIcon />}
            </div>
            <div className="flex-grow min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">
                {item.type}
              </p>
              <p className="text-sm text-gray-500">
                {new Date(item.date.replace(/\./g, "-")).toLocaleDateString()}
              </p>
            </div>
            <div className="flex-shrink-0 text-right">
              <p className="text-sm font-medium text-gray-900">
                {item.amount > 0
                  ? `+ $${item.amount.toFixed(2)}`
                  : `- $${Math.abs(item.amount).toFixed(2)}`}
              </p>
              {item.description && (
                <p className="text-sm text-gray-500">{item.description}</p>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default TransactionHistory;
