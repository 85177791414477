import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseNewsConfig = {
  apiKey: process.env.REACT_APP_NEWS_API_KEY,
  authDomain: process.env.REACT_APP_NEWS_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_NEWS_DATABASE_URL,
  projectId: process.env.REACT_APP_NEWS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_NEWS_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_NEWS_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_NEWS_APP_ID,
};

const newsApp = initializeApp(firebaseNewsConfig, "newsApp");
const newsDb = getDatabase(newsApp);

export { newsDb };
