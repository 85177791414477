import PropTypes from "prop-types";
import MainCard from "./MainCard";

const calculateDaysSinceActivation = (activationDate) => {
  if (!activationDate) return "N/A";
  const activation = new Date(
    activationDate.replace(/년|월/g, "-").replace(/일/g, "")
  );
  const today = new Date();
  const diffTime = Math.abs(today - activation);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

const AnalyticEcommerce = ({ data }) => {
  const daysSinceActivation =
    data.activationDate !== "N/A"
      ? calculateDaysSinceActivation(data.activationDate)
      : "N/A";

  return (
    <MainCard className="p-4">
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <div className="flex items-center mb-4 sm:mb-0">
          <img
            src="https://source.unsplash.com/random/100x100"
            alt="Avatar"
            className="w-12 h-12 sm:w-16 sm:h-16 rounded-full"
          />
          <div className="ml-4">
            <h4 className="text-lg sm:text-xl font-bold">{data.title}</h4>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-2 sm:gap-4 text-center mt-4 sm:mt-6">
        <div className="border border-gray-200 rounded-lg p-2">
          <p className="text-base sm:text-xl font-bold">
            {data.profitRate.toFixed(2)}
          </p>
          <p className="text-xs sm:text-sm text-gray-500">Profit Rate%</p>
        </div>
        <div className="border border-gray-200 rounded-lg p-2">
          <p className="text-base sm:text-xl font-bold">
            {daysSinceActivation}
          </p>
          <p className="text-xs sm:text-sm text-gray-500">Days Open</p>
        </div>
        <div className="border border-gray-200 rounded-lg p-2">
          <p className="text-base sm:text-xl font-bold text-green-500">
            {data.leverage}
          </p>
          <p className="text-xs sm:text-sm text-gray-500">Leverage</p>
        </div>
        <div className="border border-gray-200 rounded-lg p-2">
          <p className="text-base sm:text-xl font-bold">{data.broker}</p>
          <p className="text-xs sm:text-sm text-gray-500">Broker</p>
        </div>
        <div className="border border-gray-200 rounded-lg p-2">
          <p className="text-base sm:text-xl font-bold">
            ${data.capital.toFixed(2)}
          </p>
          <p className="text-xs sm:text-sm text-gray-500">자본금</p>
        </div>
        <div className="border border-gray-200 rounded-lg p-2">
          <p className="text-base sm:text-xl font-bold">
            ${data.currentAsset.toFixed(2)}
          </p>
          <p className="text-xs sm:text-sm text-gray-500">현재 자산</p>
        </div>
      </div>
      <div className="mt-4">
        <p className="text-xs sm:text-sm text-gray-500">
          현재 자산은 ${data.currentAsset.toFixed(2)} 이며{" "}
          {data.currentAssetDate} {data.currentAssetTime} 기준입니다.
        </p>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-4">
        <p className="text-xs sm:text-sm text-gray-500 mb-2 sm:mb-0">
          {data.extra}
        </p>
        <button className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors text-sm sm:text-base">
          분석
        </button>
      </div>
      <div className="mt-4 sm:mt-6">
        <h6 className="text-xs sm:text-sm text-gray-500">Server</h6>
        <p className="text-base sm:text-xl">{data.server}</p>
      </div>
    </MainCard>
  );
};

AnalyticEcommerce.propTypes = {
  data: PropTypes.shape({
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.string,
    percentage: PropTypes.number,
    isLoss: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    activationDate: PropTypes.string,
    capital: PropTypes.number.isRequired,
    currentAsset: PropTypes.number.isRequired,
    currentAssetDate: PropTypes.string.isRequired,
    currentAssetTime: PropTypes.string.isRequired,
    leverage: PropTypes.string.isRequired,
    broker: PropTypes.string.isRequired,
    server: PropTypes.string.isRequired,
  }).isRequired,
};

AnalyticEcommerce.defaultProps = {
  data: {
    color: "primary",
  },
};

export default AnalyticEcommerce;
