import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // 페이지 이동을 위한 useNavigate 훅
import newsImage from "../../assets/images/news.png";

const getCurrentDate = () => {
  const now = new Date();
  const day = now.getDate();
  const month = now.toLocaleString("default", { month: "short" }).toUpperCase();
  return { day, month };
};

const CardNews = ({ imageUrl, title, content, link }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { day, month } = getCurrentDate();
  const navigate = useNavigate(); // 페이지 이동을 위한 훅 사용

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleMoreClick = (event) => {
    event.stopPropagation(); // 부모 컴포넌트로 이벤트 버블링을 막음
    navigate(link); // 클릭 시 페이지 이동
  };

  return (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
      style={{ width: "100%", aspectRatio: "1 / 1.4" }}
      onClick={handleClick}
    >
      <div className="relative h-full">
        <div
          className={`relative h-3/5 transition-all duration-300 ${
            isExpanded ? "h-1/3" : ""
          }`}
        >
          <img
            src={imageUrl}
            alt={title}
            className={`w-full h-full object-cover transition-all duration-300 ${
              isExpanded ? "filter blur-sm" : ""
            }`}
          />
          <div className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-12 h-12 flex flex-col items-center justify-center">
            <span className="text-xl">{day}</span>
            <span className="text-xs">{month}</span>
          </div>
          <div className="absolute bottom-0 left-0 bg-red-500 text-white px-2 py-1 text-xs">
            NEWS
          </div>
        </div>
        <div
          className={`absolute bottom-0 left-0 right-0 bg-white transition-all duration-300 ${
            isExpanded ? "h-2/3" : "h-2/5"
          }`}
        >
          <div className="p-4 h-full flex flex-col">
            <h2 className="text-xl mb-2">{title}</h2>
            <p className="text-red-500 mb-2">{content.split(".")[0]}.</p>
            <div
              className={`overflow-hidden transition-all duration-300 ${
                isExpanded ? "flex-grow" : "h-0"
              }`}
            >
              <p className="text-gray-600">{content}</p>
            </div>
            <div className="mt-auto text-sm text-gray-500 flex justify-between"></div>
            {isExpanded && (
              <div className="text-center mt-4">
                <button
                  onClick={handleMoreClick}
                  className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300"
                >
                  More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CardNewsContainer = () => {
  const newsData = {
    imageUrl: newsImage,
    title: "ALL THAT FX NEWS",
    content:
      "Breaking News, World News Updates. City Lights in New York: The city that never sleeps. New York, the largest city in the U.S., is an architectural marvel with plenty of historic monuments, magnificent buildings and countless dazzling skyscrapers.",
    link: "/news", // More 클릭 시 이동할 페이지 경로
  };

  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-4 overflow-hidden">
      <CardNews {...newsData} />
    </div>
  );
};

export default CardNewsContainer;
