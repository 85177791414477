import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../../config/firebaseConfig";
import { ref, get, set } from "firebase/database";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../../Loading.jsx"; //
import OrdersTable from "./OrdersTable.jsx";
import IncomeAreaChart from "./IncomeAreaChart";
import MonthlyBarChart from "./MonthlyBarChart";
import ReportAreaChart from "./ReportAreaChart";
import MainCard from "./MainCard.jsx";
import AnalyticEcommerce from "./AnalyticEcommerce.jsx";
import TransactionHistory from "./TransactionHistory";
import PieChartComponent from "./PieChartComponent";
import ProfitRateChart from "./ProfitRateChart";
import DailyProfitChart from "./DailyProfitChart";
import AnalysisReport from "./AnalysisReport";

const xAxisOptions = [
  { value: 1, label: "1분" },
  { value: 5, label: "5분" },
  { value: 15, label: "15분" },
  { value: 30, label: "30분" },
  { value: 60, label: "1시간" },
  { value: 240, label: "4시간" },
];

const formatDateToDisplay = (date) => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();
  return `${year}년 ${month}월 ${day}일`;
};

const AccountDetailPage = () => {
  const { nodeId } = useParams();
  const navigate = useNavigate();

  const [allData, setAllData] = useState({});
  const [dailyProfitData, setDailyProfitData] = useState({});
  const [capital, setCapital] = useState(0);
  const [xAxisInterval, setXAxisInterval] = useState(240);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showAllData, setShowAllData] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [chartTabValue, setChartTabValue] = useState(0);
  const [overviewData, setOverviewData] = useState({
    profitRate: 0,
    maxDrawdown: 0,
    totalTrades: 0,
    activationDate: "N/A",
    capital: 0,
    currentAsset: 0,
    currentAssetDate: "N/A",
    currentAssetTime: "N/A",
    live: 0,
    leverage: "N/A",
    broker: "N/A",
    server: "N/A",
  });
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchMDDData = async () => {
      const mddRef = ref(db, `mt4data/${nodeId}/MDD`);
      const snapshot = await get(mddRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setAllData(data);
      }
    };

    const fetchCapital = async () => {
      const totalDepositRef = ref(db, `mt4data/${nodeId}/info/Total Deposit`);
      const depositSnapshot = await get(totalDepositRef);
      if (depositSnapshot.exists()) {
        const capital = parseFloat(depositSnapshot.val());
        console.log("Capital fetched:", capital);
        setCapital(capital);
      }
    };

    const fetchDailyProfits = async () => {
      const dailyProfitRef = ref(db, `mt4data/${nodeId}/analysis/DailyProfit`);
      const snapshot = await get(dailyProfitRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log("Daily Profits fetched:", data);
        setDailyProfitData(data);
      } else {
        console.log("No daily profits data found");
      }
    };

    const fetchOverviewData = async () => {
      const totalDepositsRef = ref(db, `mt4data/${nodeId}/info/Total Deposit`);
      const totalWithdrawalsRef = ref(
        db,
        `mt4data/${nodeId}/info/Total Withdrawal`
      );
      const winCountRef = ref(db, `mt4data/${nodeId}/analysis/WinCount`);
      const loseCountRef = ref(db, `mt4data/${nodeId}/analysis/LoseCount`);
      const dailyProfitRef = ref(db, `mt4data/${nodeId}/analysis/DailyProfit`);
      const lastMaxDrawdownCalcRef = ref(
        db,
        `mt4data/${nodeId}/info/Last Max Drawdown Calc`
      );
      const maxDrawdownRef = ref(db, `mt4data/${nodeId}/info/Max Drawdown`);
      const mddRef = ref(db, `mt4data/${nodeId}/MDD`);
      const liveRef = ref(db, `mt4data/${nodeId}/info/Live`);
      const leverageRef = ref(db, `mt4data/${nodeId}/info/Leverage`);
      const brokerRef = ref(db, `mt4data/${nodeId}/info/Broker`);
      const serverRef = ref(db, `mt4data/${nodeId}/info/Server`);

      const [
        totalDepositsSnapshot,
        totalWithdrawalsSnapshot,
        winCountSnapshot,
        loseCountSnapshot,
        dailyProfitSnapshot,
        lastMaxDrawdownCalcSnapshot,
        maxDrawdownSnapshot,
        mddSnapshot,
        liveSnapshot,
        leverageSnapshot,
        brokerSnapshot,
        serverSnapshot,
      ] = await Promise.all([
        get(totalDepositsRef),
        get(totalWithdrawalsRef),
        get(winCountRef),
        get(loseCountRef),
        get(dailyProfitRef),
        get(lastMaxDrawdownCalcRef),
        get(maxDrawdownRef),
        get(mddRef),
        get(liveRef),
        get(leverageRef),
        get(brokerRef),
        get(serverRef),
      ]);

      const mddData = mddSnapshot.val();
      const dates = Object.keys(mddData).filter((key) =>
        key.match(/^\d{4}_\d{2}_\d{2}$/)
      );

      let maxDrawdown = maxDrawdownSnapshot.val() || 0;
      let lastCalcDateTime = lastMaxDrawdownCalcSnapshot.val()
        ? lastMaxDrawdownCalcSnapshot.val().split("_")
        : [null, null];
      let lastCalcDate = lastCalcDateTime[0];
      let lastCalcTime = lastCalcDateTime[1];

      dates.forEach((date) => {
        const timeData = mddData[date];
        Object.keys(timeData).forEach((time) => {
          const balance = parseFloat(timeData[time].Balance);
          const equity = parseFloat(timeData[time].Equity);
          const drawdown = ((balance - equity) / balance) * 100;
          const currentDateTime = `${date}_${time}`;

          if (
            !lastCalcDate ||
            currentDateTime > `${lastCalcDate}_${lastCalcTime}`
          ) {
            if (drawdown > maxDrawdown) {
              maxDrawdown = drawdown;
            }
          }
        });
      });

      let latestDate = dates.sort().pop();
      let latestTimeData = mddData[latestDate];
      let times = Object.keys(latestTimeData).filter((key) =>
        key.match(/^\d{2}_\d{2}$/)
      );
      let latestTime = times.sort().pop();
      let currentBalance = parseFloat(latestTimeData[latestTime].Balance);

      if (maxDrawdown > (overviewData.maxDrawdown || 0)) {
        await set(maxDrawdownRef, maxDrawdown);
        await set(lastMaxDrawdownCalcRef, `${latestDate}_${latestTime}`);
      }

      const winCount = parseFloat(winCountSnapshot.val()) || 0;
      const loseCount = parseFloat(loseCountSnapshot.val()) || 0;
      const totalTrades = winCount + loseCount;

      const totalDeposits = parseFloat(totalDepositsSnapshot.val()) || 0;
      const totalWithdrawals =
        Math.abs(parseFloat(totalWithdrawalsSnapshot.val())) || 0;
      const capital = totalDeposits;

      const dailyProfitData = dailyProfitSnapshot.val();
      const firstProfitDate = dailyProfitData
        ? Object.keys(dailyProfitData).sort()[0]
        : null;
      const formattedActivationDate = firstProfitDate
        ? `${firstProfitDate.slice(0, 4)}년 ${parseInt(
            firstProfitDate.slice(5, 7)
          )}월 ${parseInt(firstProfitDate.slice(8))}일`
        : "N/A";

      const live = liveSnapshot.val() || 0;
      const leverage = leverageSnapshot.val() || "N/A";
      const broker = brokerSnapshot.val() || "N/A";
      const server = serverSnapshot.val() || "N/A";

      setOverviewData({
        profitRate:
          currentBalance && totalDeposits
            ? ((currentBalance - totalDeposits + totalWithdrawals) /
                totalDeposits) *
              100
            : 0,
        maxDrawdown: maxDrawdown || 0,
        totalTrades: totalTrades || 0,
        activationDate: formattedActivationDate,
        capital: capital,
        currentAsset: currentBalance,
        currentAssetDate: latestDate.replace(/_/g, "-"),
        currentAssetTime: latestTime.replace(/_/g, ":"),
        live: live,
        leverage: leverage,
        broker: broker,
        server: server,
      });
      setIsDataLoaded(true);
    };

    if (nodeId) {
      console.log("Fetching data for node:", nodeId);
      fetchMDDData();
      fetchCapital();
      fetchDailyProfits();
      fetchOverviewData();
    }
  }, [nodeId]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowAllData(false);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return `${year}_${month}_${day}`;
  };

  const getSelectedDateData = () => {
    if (!selectedDate) return null;
    const formattedDate = formatDate(selectedDate);
    return allData[formattedDate] || null;
  };

  const filteredData = Object.keys(allData).reduce((acc, key) => {
    if (key !== "Account" && key !== "type") {
      acc[key] = allData[key];
    }
    return acc;
  }, {});

  const selectedDateData = getSelectedDateData();

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const handleChartTabChange = (newValue) => {
    setChartTabValue(newValue);
  };

  // 데이터 로딩 중일 때는 Loading 컴포넌트를 표시
  if (!isDataLoaded) {
    return <Loading />;
  }

  return (
    <div className="container mx-auto p-2 sm:p-3 bg-gray-100">
      <div className="bg-white p-2 sm:p-3 rounded-lg shadow-lg">
        <div className="bg-white p-2 sm:p-3 rounded-lg shadow">
          <div className="grid gap-2 sm:gap-4 md:gap-6">
            <div className="col-span-12">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <h5 className="text-lg sm:text-xl font-bold text-gray-800 mb-2 sm:mb-0">
                  Detail Page
                </h5>
                <button
                  onClick={() => navigate(-1)}
                  className="px-3 py-1 sm:px-4 sm:py-2 text-sm sm:text-base text-gray-800 font-bold border border-gray-300 rounded hover:bg-gray-100"
                >
                  뒤로가기
                </button>
              </div>
            </div>
            <div className="col-span-12">
              <AnalyticEcommerce
                data={{
                  title: nodeId,
                  count: overviewData.live.toString(),
                  profitRate: overviewData.profitRate,
                  maxDrawdown: overviewData.maxDrawdown,
                  totalTrades: overviewData.totalTrades,
                  activationDate: overviewData.activationDate,
                  capital: overviewData.capital,
                  currentAsset: overviewData.currentAsset,
                  currentAssetDate: overviewData.currentAssetDate,
                  currentAssetTime: overviewData.currentAssetTime,
                  leverage: overviewData.leverage,
                  broker: overviewData.broker,
                  server: overviewData.server,
                  extra: `자본금 대비 ${overviewData.profitRate.toFixed(
                    2
                  )}% 수익(${
                    overviewData.profitRate < 0 ? "손실" : "수익"
                  })중입니다`,
                  color:
                    overviewData.profitRate < 0
                      ? "text-red-500"
                      : "text-green-500",
                  isLoss: overviewData.profitRate < 0,
                }}
              />
            </div>
            <div className="col-span-12 overflow-x-auto">
              <div className="bg-white p-2 rounded-lg shadow">
                <div className="flex justify-start sm:justify-center">
                  {["차트", "거래", "트레이딩", "서포트", "분석"].map(
                    (tab, index) => (
                      <button
                        key={tab}
                        onClick={() => handleTabChange(index)}
                        className={`px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm ${
                          tabValue === index
                            ? "text-blue-500 border-b-2 border-blue-500"
                            : "text-gray-500"
                        }`}
                      >
                        {tab}
                      </button>
                    )
                  )}
                </div>
              </div>
            </div>
            {tabValue === 0 && (
              <>
                <div className="col-span-12">
                  <div className="bg-white p-2 sm:p-3 rounded-lg shadow">
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                      <h5 className="text-lg sm:text-xl font-bold text-gray-800 mb-2 sm:mb-0">
                        Chart Data
                      </h5>
                      <div className="flex flex-wrap items-center space-y-2 sm:space-y-0 sm:space-x-2">
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          dateFormat="yyyy-MM-dd"
                          customInput={
                            <button className="px-2 sm:px-3 py-1 text-xs sm:text-sm text-gray-800 border border-gray-300 rounded">
                              날짜 선택
                            </button>
                          }
                        />
                        {selectedDate && (
                          <span className="text-xs sm:text-sm font-bold text-gray-800">
                            {formatDateToDisplay(selectedDate)}
                          </span>
                        )}
                        <button
                          onClick={() => {
                            setShowAllData(true);
                            setSelectedDate(new Date());
                          }}
                          className={`px-2 sm:px-3 py-1 text-xs sm:text-sm ${
                            showAllData
                              ? "bg-blue-500 text-white"
                              : "bg-gray-200 text-gray-800"
                          } rounded`}
                        >
                          All
                        </button>
                        <select
                          value={xAxisInterval}
                          onChange={(e) =>
                            setXAxisInterval(parseInt(e.target.value, 10))
                          }
                          className="bg-white text-xs sm:text-sm text-gray-800 border border-gray-300 rounded px-1 sm:px-2 py-1"
                        >
                          {xAxisOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="mt-4">
                      <MainCard content={false} className="p-2">
                        <div className="flex justify-start sm:justify-center mb-2 overflow-x-auto">
                          {[
                            "Profit Rate Chart",
                            "Daily Profit Chart",
                            "MDD Chart",
                          ].map((tab, index) => (
                            <button
                              key={tab}
                              onClick={() => handleChartTabChange(index)}
                              className={`px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm whitespace-nowrap ${
                                chartTabValue === index
                                  ? "text-blue-500 border-b-2 border-blue-500"
                                  : "text-gray-500"
                              }`}
                            >
                              {tab}
                            </button>
                          ))}
                        </div>
                        <div className="pt-1 pr-2">
                          {chartTabValue === 0 && (
                            <ProfitRateChart
                              capital={capital}
                              dailyProfits={dailyProfitData}
                              yAxisInterval={50}
                            />
                          )}
                          {chartTabValue === 1 && (
                            <DailyProfitChart
                              dailyProfits={dailyProfitData}
                              yAxisInterval={50}
                            />
                          )}
                          {chartTabValue === 2 &&
                            (showAllData ? (
                              <IncomeAreaChart
                                data={filteredData}
                                yAxisInterval={50}
                                xAxisInterval={xAxisInterval}
                              />
                            ) : (
                              selectedDateData && (
                                <IncomeAreaChart
                                  data={{
                                    [formatDate(selectedDate)]:
                                      selectedDateData,
                                  }}
                                  yAxisInterval={50}
                                  xAxisInterval={xAxisInterval}
                                />
                              )
                            ))}
                        </div>
                      </MainCard>
                    </div>
                  </div>
                </div>
                <div className="col-span-12">
                  <div className="bg-white p-3 rounded-lg shadow mt-4">
                    <h5 className="text-xl font-bold text-gray-800">
                      Income Overview
                    </h5>
                    <MonthlyBarChart />
                  </div>
                </div>
              </>
            )}
            {tabValue === 1 && (
              <>
                <div className="col-span-12">
                  <h5 className="text-xl font-bold text-gray-800">거래 분석</h5>
                </div>
                <div className="col-span-12">
                  <MainCard className="mt-4" content={false}>
                    <PieChartComponent />
                  </MainCard>
                </div>
                <div className="col-span-12">
                  <h5 className="text-xl font-bold text-gray-800">
                    Analytics Report
                  </h5>
                </div>
                <div className="col-span-12">
                  <MainCard className="mt-4" content={false}>
                    <ReportAreaChart />
                  </MainCard>
                </div>
              </>
            )}
            {tabValue === 2 && (
              <>
                <div className="col-span-12">
                  <h5 className="text-xl font-bold text-gray-800">Live</h5>
                </div>
                <div className="col-span-12">
                  <MainCard className="mt-4" content={false}>
                    <OrdersTable />
                  </MainCard>
                </div>
              </>
            )}
            {tabValue === 3 && (
              <>
                <div className="col-span-12">
                  <h5 className="text-xl font-bold text-gray-800">
                    입출금 내역
                  </h5>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <MainCard className="mt-4" content={false}>
                    <TransactionHistory />
                  </MainCard>
                </div>
                <div className="col-span-12">
                  <h5 className="text-xl font-bold text-gray-800">HELP</h5>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <MainCard className="mt-4" content={false}></MainCard>
                </div>
              </>
            )}
            {tabValue === 4 && (
              <div className="col-span-12">
                <AnalysisReport />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetailPage;
