import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { db } from "../../config/firebaseConfig";
import { ref, get } from "firebase/database";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// 날짜별 데이터 정렬 및 변환
const formatProfitData = (dailyProfits) => {
  let cumulativeProfit = 0;
  return Object.keys(dailyProfits)
    .sort((a, b) => new Date(a) - new Date(b)) // 날짜 순으로 정렬 (오름차순)
    .map((date) => {
      const value = dailyProfits[date];
      cumulativeProfit += parseFloat(value);
      return { date, profit: parseFloat(cumulativeProfit.toFixed(2)) };
    });
};

// 커스텀 툴팁 컴포넌트 (마지막 데이터 포인트 표시)
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const lastDataPoint = payload[0].payload;
    return (
      <div className="custom-tooltip bg-white p-2 border border-gray-200 rounded shadow">
        <p className="label">{`Date: ${lastDataPoint.date}`}</p>
        <p className="intro">{`Profit: $${lastDataPoint.profit}`}</p>
      </div>
    );
  }

  return null;
};

const MiniDailyProfitChart = ({ nodeId, width, height }) => {
  const [data, setData] = useState([]);
  const [tooltipData, setTooltipData] = useState(null); // 툴팁 데이터 상태

  useEffect(() => {
    const fetchDailyProfits = async () => {
      console.log(`Fetching daily profits for node: ${nodeId}`);
      const profitRef = ref(db, `mt4data/${nodeId}/analysis/DailyProfit`);
      const snapshot = await get(profitRef);
      if (snapshot.exists()) {
        const rawData = snapshot.val();
        const profitData = formatProfitData(rawData);
        setData(profitData);

        // 가장 최신(마지막) 데이터로 툴팁 설정
        if (profitData.length > 0) {
          setTooltipData(profitData[profitData.length - 1]); // 마지막 데이터 설정
        }
      } else {
        console.log(`No daily profits data available for node: ${nodeId}`);
      }
    };

    if (nodeId) {
      fetchDailyProfits();
    }
  }, [nodeId]);

  return (
    <div style={{ width, height }}>
      {data.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorProfit" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="date" hide />
            <YAxis hide />
            <Tooltip
              content={<CustomTooltip />}
              isAnimationActive={false}
              active={tooltipData !== null} // 툴팁 활성화
              payload={tooltipData ? [{ payload: tooltipData }] : []} // 최신 날짜 데이터 전송
              position={{ x: "auto", y: 0 }} // 툴팁 위치 설정
            />
            <Area
              type="monotone"
              dataKey="profit"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorProfit)"
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <p className="text-gray-500 text-sm">No daily profit data available.</p>
      )}
    </div>
  );
};

MiniDailyProfitChart.propTypes = {
  nodeId: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

MiniDailyProfitChart.defaultProps = {
  width: "100px",
  height: "100px",
};

export default MiniDailyProfitChart;
