import React, { useEffect, useRef, useState } from "react";

const TvCard = () => {
  const containerRef = useRef(null);
  const [iframeElement, setIframeElement] = useState(null);

  useEffect(() => {
    if (containerRef.current) {
      try {
        const iframe = document.createElement("iframe");
        iframe.src =
          "https://s.tradingview.com/embed-widget/events/?locale=en#%7B%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22400%22%2C%22importanceFilter%22%3A%22-1%2C0%2C1%22%2C%22currencyFilter%22%3A%22USD%2CEUR%2CJPY%2CGBP%2CCHF%2CAUD%2CCAD%2CNZD%2CCNY%22%2C%22utm_source%22%3A%22www.example.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22events%22%7D";
        iframe.style.width = "100%";
        iframe.style.height = "300px";
        iframe.style.border = "none";
        containerRef.current.appendChild(iframe);
        setIframeElement(iframe);
      } catch (error) {
        console.error("Error loading TradingView widget:", error);
      }
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
      setIframeElement(null);
    };
  }, []);

  return (
    <div className="bg-gray-100 p-2 rounded-lg mb-4">
      <div className="bg-white p-2 rounded-lg shadow-sm overflow-hidden">
        <h2 className="text-lg font-bold mb-2">Economic Calendar</h2>
        <div
          ref={containerRef}
          className="rounded overflow-hidden"
          style={{ maxWidth: "100%" }}
        ></div>
      </div>
    </div>
  );
};

export default TvCard;
