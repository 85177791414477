import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

const areaChartOptions = (yAxisInterval) => ({
  chart: {
    height: 450,
    type: "area",
    toolbar: {
      show: true,
      tools: {
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  grid: {
    strokeDashArray: 0,
  },
  yaxis: {
    tickAmount: yAxisInterval ? 450 / yAxisInterval : undefined,
    labels: {
      formatter: (val) => Math.round(val) + "%",
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeFormatter: {
        year: "yyyy",
        month: "MMM yyyy",
        day: "dd MMM",
        hour: "HH:mm",
      },
      show: true,
      rotate: -45,
      maxHeight: 100,
    },
  },
});

const calculateProfitRate = (capital, dailyProfits) => {
  let cumulativeProfit = 0;
  return Object.keys(dailyProfits).map((date) => {
    const value = dailyProfits[date];
    const dateTime = new Date(date.replace(/_/g, "-")).getTime();
    cumulativeProfit += parseFloat(value);
    const rate = (cumulativeProfit / capital) * 100;
    return { x: dateTime, y: rate };
  });
};

const ProfitRateChart = ({ capital, dailyProfits, yAxisInterval }) => {
  const [options, setOptions] = useState(areaChartOptions(yAxisInterval));
  const [series, setSeries] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (Object.keys(dailyProfits).length === 0 || capital === 0) {
      setIsDataLoaded(false);
    } else {
      console.log("Capital:", capital);
      console.log("Daily Profits:", dailyProfits);
      const profitRateData = calculateProfitRate(capital, dailyProfits);
      console.log("Profit Rate Data:", profitRateData);
      setOptions(areaChartOptions(yAxisInterval));
      setSeries([{ name: "Profit Rate", data: profitRateData }]);
      setIsDataLoaded(true);
    }
  }, [capital, dailyProfits, yAxisInterval]);

  if (!isDataLoaded) {
    return (
      <div className="flex justify-center items-center h-[450px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="w-full h-[450px]">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={450}
      />
    </div>
  );
};

ProfitRateChart.propTypes = {
  capital: PropTypes.number.isRequired,
  dailyProfits: PropTypes.object.isRequired,
  yAxisInterval: PropTypes.number,
};

export default ProfitRateChart;
