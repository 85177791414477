// utils/tooltipHelpers.js

export const handleTooltipToggle = (
  event,
  nodeId,
  details,
  activeNodeId,
  setTooltipVisible,
  setTooltipData,
  setActiveNodeId,
  setTooltipPosition
) => {
  if (activeNodeId === nodeId) {
    setTooltipVisible(false);
    setActiveNodeId(null);
  } else {
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setTooltipData(details);
    setTooltipVisible(true);
    setActiveNodeId(nodeId);
  }
};
