export const strengthColor = (count) => {
  if (count < 2) return { label: "Poor", color: "#FF1744" };
  if (count < 3) return { label: "Weak", color: "#FFEA00" };
  if (count < 4) return { label: "Normal", color: "#FFB74D" };
  if (count < 5) return { label: "Good", color: "#00E676" };
  if (count < 6) return { label: "Strong", color: "#00B0FF" };
  return { label: "Poor", color: "#FF1744" };
};

export const strengthIndicator = (password) => {
  let strengths = 0;
  if (password.length > 5) strengths++;
  if (password.length > 7) strengths++;
  if (/[A-Z]/.test(password)) strengths++;
  if (/[0-9]/.test(password)) strengths++;
  if (/[A-Za-z0-9]/.test(password)) strengths++;
  return strengths;
};
