import React, { useState, useEffect, useCallback } from "react";
import { db, auth } from "../../config/firebaseConfig";
import { ref, get } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const LeaderBoard = () => {
  const [users, setUsers] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [activeNodeId, setActiveNodeId] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchCurrentUserData = useCallback(async (user) => {
    if (user) {
      const userRef = ref(db, `users/${user.uid}`);
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        setCurrentUser({
          uid: user.uid,
          name: userData.name || "Anonymous",
          accounts: userData.account ? Object.keys(userData.account) : [],
        });
        fetchNodesForUser({
          uid: user.uid,
          name: userData.name || "Anonymous",
          accounts: userData.account ? Object.keys(userData.account) : [],
        });
      }
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchCurrentUserData(user);
      } else {
        setCurrentUser(null);
        setUsers([]);
      }
    });

    return () => unsubscribe();
  }, [fetchCurrentUserData]);

  const fetchNodesForUser = (user) => {
    if (user.accounts.length > 0) {
      fetchNodesData([user]);
    }
  };

  const fetchNodesData = async (usersWithAccounts) => {
    const nodesPromises = usersWithAccounts.flatMap((user) =>
      user.accounts.map((nodeId) => processNodeData(nodeId, user))
    );

    const nodes = await Promise.all(nodesPromises);
    const validNodes = nodes.filter((node) => node !== null);

    setUsers(validNodes);
  };

  const calculateRiskScore = (
    operationPeriod,
    profitRate,
    currentBalance,
    currentMDD
  ) => {
    let score = 0;
    let details = [];

    // 1. 계좌 구동기간
    if (operationPeriod <= 180) {
      score += 0.5;
      details.push(`구동기간 (${operationPeriod}일): 0.5점`);
    } else if (operationPeriod <= 365) {
      score += 1;
      details.push(`구동기간 (${operationPeriod}일): 1점`);
    } else {
      score += 2;
      details.push(`구동기간 (${operationPeriod}일): 2점`);
    }

    // 2. 수익률
    if (profitRate >= 50) {
      score += 4;
      details.push(`수익률 (${profitRate.toFixed(2)}%): 4점`);
    } else if (profitRate >= 30) {
      score += 3;
      details.push(`수익률 (${profitRate.toFixed(2)}%): 3점`);
    } else if (profitRate >= 10) {
      score += 2;
      details.push(`수익률 (${profitRate.toFixed(2)}%): 2점`);
    } else if (profitRate >= 0) {
      score += 1;
      details.push(`수익률 (${profitRate.toFixed(2)}%): 1점`);
    } else {
      details.push(`수익률 (${profitRate.toFixed(2)}%): 0점`);
    }

    // 3. MDD
    let mdd;
    if (currentMDD >= 0) {
      mdd = 0;
      score += 4;
      details.push(`MDD (0%): 4점 (만점)`);
    } else {
      mdd = (Math.abs(currentMDD) / currentBalance) * 100;
      if (mdd <= 10) {
        score += 4;
        details.push(`MDD (${mdd.toFixed(2)}%): 4점`);
      } else if (mdd <= 20) {
        score += 2;
        details.push(`MDD (${mdd.toFixed(2)}%): 2점`);
      } else if (mdd < 30) {
        score += 1;
        details.push(`MDD (${mdd.toFixed(2)}%): 1점`);
      } else {
        details.push(`MDD (${mdd.toFixed(2)}%): 0점`);
      }
    }

    return { score, details };
  };

  const processNodeData = async (nodeId, user) => {
    const scoreRef = ref(db, `mt4data/${nodeId}/score`);
    const infoRef = ref(db, `mt4data/${nodeId}/info`);
    const [scoreSnapshot, infoSnapshot] = await Promise.all([
      get(scoreRef),
      get(infoRef),
    ]);

    if (scoreSnapshot.exists() && infoSnapshot.exists()) {
      const scoreData = scoreSnapshot.val();
      const infoData = infoSnapshot.val();

      const currentBalance = scoreData.currentBalance || 0;
      const operationPeriod = scoreData.operationPeriod || 0;
      const totalDeposit = scoreData.totalDeposit || 0;
      const totalWithdrawal = Math.abs(scoreData.totalWithdrawal || 0);
      const currentMDD = scoreData.currentMDD || 0;

      const profitRate =
        totalDeposit > 0
          ? ((currentBalance + totalWithdrawal - totalDeposit) / totalDeposit) *
            100
          : 0;

      const { score: riskScore, details: riskDetails } = calculateRiskScore(
        operationPeriod,
        profitRate,
        currentBalance,
        currentMDD
      );

      return {
        nodeId,
        name: user.name,
        profitRate,
        broker: infoData.Broker || "N/A",
        currentAsset: currentBalance,
        daysOpen: operationPeriod,
        riskScore,
        riskDetails,
      };
    }
    return null;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const renderBrokerLogo = (broker) => {
    // Implement broker logo rendering logic here
    return <span>{broker}</span>;
  };

  const handleNodeClick = (nodeId) => {
    navigate(`/dashboard/${nodeId}`);
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    }
    return 0;
  });

  const handleTooltipToggle = (event, nodeId, details) => {
    if (activeNodeId === nodeId) {
      setTooltipVisible(false);
      setActiveNodeId(null);
    } else {
      const rect = event.currentTarget.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top + window.scrollY,
        left: rect.right + window.scrollX,
      });
      setTooltipData(details);
      setTooltipVisible(true);
      setActiveNodeId(nodeId);
    }
  };

  const renderRiskScoreWithSVG = (score, details, nodeId) => (
    <div className="flex items-center relative">
      <span>{score.toFixed(1)}</span>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ml-2 cursor-pointer"
        onClick={(e) => handleTooltipToggle(e, nodeId, details)}
      >
        <g clipPath="url(#clip0_1_276)">
          <path
            d="M12.3211 8.42653C12.2493 9.46863 11.6551 10.2002 10.969 10.5103C10.2416 10.8389 9.50627 10.6577 9.13916 10.059C8.5381 9.07885 8.95111 8.39666 9.3505 7.73691C9.80335 6.98881 10.3167 6.14088 9.82434 4.49194C9.43567 3.19016 8.67449 2.09725 7.56189 1.24356C7.21505 0.977471 6.88611 0.777346 6.61916 0.634033C6.99201 1.5416 7.47686 3.23044 6.9704 5.09631C6.64918 6.27985 5.80023 7.17672 4.97922 8.04406C3.89789 9.1865 2.77968 10.3678 2.77968 12.2553C2.77968 13.7195 3.28983 14.886 4.29591 15.7222C5.05778 16.3555 5.8352 16.6021 5.84295 16.604H7.69803L7.6592 16.6033C7.65205 16.5977 6.94531 16.0398 6.93314 15.4001C6.92747 15.101 7.08439 14.8142 7.39956 14.5475C7.87295 14.1469 8.13314 13.7156 8.19503 13.2289C8.23289 12.9313 8.19462 12.6167 8.0783 12.277C8.86348 12.6063 9.67567 13.3253 9.9509 14.2178C10.1981 15.0194 9.96943 15.8437 9.2903 16.604H11.3205C12.6504 15.5622 13.399 14.3136 13.5458 12.8931C13.7329 11.0819 12.9048 9.37603 12.3211 8.42653Z"
            fill="#0C0B0B"
          />
          <path
            d="M7.69805 16.604L9.26357 16.634C9.27265 16.6241 9.28135 16.614 9.29029 16.604H7.69805Z"
            fill="#0C0B0B"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_276">
            <rect
              width="15.2"
              height="16"
              fill="white"
              transform="translate(0.576172 0.634033)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );

  const renderDaysOpen = (days) => (
    <div className="flex items-center">
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mr-1"
      >
        <path
          d="M8.23389 0.95105C3.82251 0.95105 0.233887 4.53967 0.233887 8.95105C0.233887 13.3624 3.82251 16.951 8.23389 16.951C12.6453 16.951 16.2339 13.3624 16.2339 8.95105C16.2339 4.53967 12.6453 0.95105 8.23389 0.95105ZM12.0386 13.089C11.9086 13.219 11.7379 13.2844 11.5673 13.2844C11.3966 13.2844 11.2258 13.219 11.0959 13.089L7.76257 9.75574C7.63721 9.6311 7.56726 9.46167 7.56726 9.28442V4.95105C7.56726 4.5824 7.86584 4.28442 8.23389 4.28442C8.60193 4.28442 8.90051 4.5824 8.90051 4.95105V9.00842L12.0386 12.1464C12.2992 12.4071 12.2992 12.8284 12.0386 13.089Z"
          fill="#0C0B0B"
        />
      </svg>
      <span>D {days}</span>
    </div>
  );

  const renderDesktopView = () => (
    <table className="min-w-full">
      <thead>
        <tr className="bg-gray-100">
          <th className="px-4 py-2 text-left">계좌</th>
          <th
            className="px-4 py-2 text-left cursor-pointer"
            onClick={() => handleRequestSort("profitRate")}
          >
            수익률
          </th>
          <th className="px-4 py-2 text-left">브로커</th>
          <th
            className="px-4 py-2 text-left cursor-pointer"
            onClick={() => handleRequestSort("currentAsset")}
          >
            현재자산
          </th>
          <th
            className="px-4 py-2 text-left cursor-pointer"
            onClick={() => handleRequestSort("daysOpen")}
          >
            구동기간
          </th>
          <th
            className="px-4 py-2 text-left cursor-pointer"
            onClick={() => handleRequestSort("riskScore")}
          >
            Score
          </th>
          <th className="px-4 py-2 text-left">상세보기</th>
        </tr>
      </thead>
      <tbody>
        {sortedUsers.map((user, index) => (
          <tr
            key={user.nodeId}
            className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
          >
            <td className="px-4 py-2 flex items-center">
              <div className="w-8 h-8 rounded-full bg-gray-300 mr-2"></div>
              {user.nodeId}
            </td>
            <td className="px-4 py-2">{user.profitRate.toFixed(2)}%</td>
            <td className="px-4 py-2">{renderBrokerLogo(user.broker)}</td>
            <td className="px-4 py-2">${user.currentAsset.toFixed(2)}</td>
            <td className="px-4 py-2">{renderDaysOpen(user.daysOpen)}</td>
            <td className="px-4 py-2">
              {renderRiskScoreWithSVG(
                user.riskScore,
                user.riskDetails,
                user.nodeId
              )}
            </td>
            <td className="px-4 py-2">
              <button
                onClick={() => handleNodeClick(user.nodeId)}
                className="border-2 border-black rounded-lg"
                style={{
                  width: "120px",
                  height: "40px",
                  boxSizing: "border-box",
                }}
              >
                Click
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderMobileView = () => (
    <div className="space-y-4">
      {sortedUsers.map((user, index) => (
        <div key={user.nodeId} className="bg-white p-4 rounded-lg shadow">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <div className="w-8 h-8 rounded-full bg-gray-300 mr-2"></div>
              <span className="font-bold">{user.nodeId}</span>
            </div>
            <span
              className={`text-sm ${
                user.profitRate >= 0 ? "text-green-500" : "text-red-500"
              }`}
            >
              {user.profitRate.toFixed(2)}%
            </span>
          </div>
          <div className="grid grid-cols-2 gap-x-2 gap-y-1 text-sm">
            <div>브로커: {renderBrokerLogo(user.broker)}</div>
            <div>자산: ${user.currentAsset.toFixed(2)}</div>
            <div className="flex items-center">
              기간: {renderDaysOpen(user.daysOpen)}
            </div>
            <div className="flex items-center">
              Score:{" "}
              {renderRiskScoreWithSVG(
                user.riskScore,
                user.riskDetails,
                user.nodeId
              )}
            </div>
          </div>
          <button
            onClick={() => handleNodeClick(user.nodeId)}
            className="mt-2 w-full border-2 border-black rounded-lg py-2"
          >
            상세보기
          </button>
        </div>
      ))}
    </div>
  );

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">My Accounts</h2>
      {currentUser ? (
        <div className="overflow-x-auto">
          {isMobile ? renderMobileView() : renderDesktopView()}
        </div>
      ) : (
        <p>로그인이 필요합니다.</p>
      )}
      {tooltipVisible && (
        <div
          className="fixed z-10 p-3 bg-white text-black rounded-lg shadow-lg border-2 border-black"
          style={{
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            minWidth: "200px",
            animation: "fadeIn 0.3s",
          }}
        >
          <button
            className="absolute top-1 right-1 text-black hover:text-gray-700"
            onClick={() => {
              setTooltipVisible(false);
              setActiveNodeId(null);
            }}
          >
            X
          </button>
          {tooltipData &&
            tooltipData.map((detail, index) => (
              <p key={index} className="text-sm mb-1">
                {detail}
              </p>
            ))}
        </div>
      )}
    </div>
  );
};

export default LeaderBoard;
