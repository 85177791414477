import React, { useState } from "react";

const NewsItem = ({ title, content, time }) => {
  const [showFullContent, setShowFullContent] = useState(false);

  const removeHtmlTags = (text) => {
    return text.replace(/<br>/g, "").replace(/<\/?[^>]+(>|$)/g, "");
  };

  const truncatedContent = showFullContent
    ? removeHtmlTags(content)
    : `${removeHtmlTags(content).slice(0, 100)}...`;

  return (
    <div className="news-item bg-white p-4 rounded-lg shadow-md mb-4">
      <div className="flex justify-between items-center mb-2">
        {/* 제목을 볼드 및 타임스탬프 추가 */}
        <h2 className="text-2xl font-bold">{removeHtmlTags(title)}</h2>
        <span className="text-sm text-gray-500">{time}</span>
      </div>
      <p className="text-sm text-gray-700 mb-2">
        {truncatedContent}
        {!showFullContent && (
          <span
            className="text-blue-500 cursor-pointer"
            onClick={() => setShowFullContent(true)}
          >
            Read more
          </span>
        )}
      </p>
      {showFullContent && (
        <span
          className="text-blue-500 cursor-pointer"
          onClick={() => setShowFullContent(false)}
        >
          Show less
        </span>
      )}
    </div>
  );
};

export default NewsItem;
