import React, { useEffect } from "react";

const TradingViewWidget = () => {
  useEffect(() => {
    // 첫 번째 위젯 (DXY 공포탐욕지수)
    setTimeout(() => {
      if (document.getElementById("dxy-widget")) {
        if (!document.getElementById("dxy-script")) {
          const script1 = document.createElement("script");
          script1.src =
            "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
          script1.id = "dxy-script";
          script1.async = true;
          script1.crossOrigin = "anonymous"; // CORS 문제 방지
          document.getElementById("dxy-widget").appendChild(script1);

          // JSON 설정 추가 (DXY 공포탐욕지수)
          script1.innerHTML = JSON.stringify({
            interval: "1m",
            width: 425,
            isTransparent: false,
            height: 450,
            symbol: "TVC:DXY",
            showIntervalTabs: true,
            displayMode: "single",
            locale: "en",
            colorTheme: "light",
          });
        }
      }
    }, 500); // DOM이 렌더링된 후 500ms 지연 후 실행

    // 두 번째 위젯 (통화쌍 관련 표)
    setTimeout(() => {
      if (document.getElementById("forex-widget")) {
        if (!document.getElementById("forex-script")) {
          const script2 = document.createElement("script");
          script2.src =
            "https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js";
          script2.id = "forex-script";
          script2.async = true;
          script2.crossOrigin = "anonymous"; // CORS 문제 방지
          document.getElementById("forex-widget").appendChild(script2);

          // JSON 설정 추가 (통화쌍)
          script2.innerHTML = JSON.stringify({
            width: "550",
            height: "400",
            currencies: ["EUR", "USD", "JPY", "GBP", "AUD", "CAD"], // 원하는 종목만 표시
            isTransparent: false,
            colorTheme: "dark",
            locale: "kr",
            backgroundColor: "#000000",
          });
        }
      }
    }, 500); // DOM이 렌더링된 후 500ms 지연 후 실행

    return () => {
      // Cleanup 시 스크립트 제거
      const dxyScript = document.getElementById("dxy-script");
      const forexScript = document.getElementById("forex-script");
      if (dxyScript) dxyScript.remove();
      if (forexScript) forexScript.remove();
    };
  }, []);

  return (
    <div className="flex flex-col space-y-4">
      {/* DXY 공포탐욕지수 위젯 */}
      <div id="dxy-widget"></div>

      {/* 통화쌍 관련 표 위젯 */}
      <div id="forex-widget"></div>
    </div>
  );
};

export default TradingViewWidget;
