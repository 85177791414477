import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { db } from "../../config/firebaseConfig";
import { ref, get } from "firebase/database";
import { EyeIcon, EyeOffIcon } from "lucide-react";

import {
  strengthColor,
  strengthIndicator,
} from "../../utils/password-strength";

// 임시 countries 데이터 (실제로는 별도의 파일이나 API에서 가져와야 함)
const countries = [
  { code: "US", name: "United States" },
  { code: "UK", name: "United Kingdom" },
  // 더 많은 국가를 추가할 수 있습니다.
];

const AuthRegister = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const navigate = useNavigate();
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [timer, setTimer] = useState(0);
  const [emailStatus, setEmailStatus] = useState("");
  const [nicknameStatus, setNicknameStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setPasswordStrength(strengthColor(temp));
  };

  const checkEmailAvailability = async (email) => {
    if (!email) {
      console.error("Email is required");
      return false;
    }

    try {
      const response = await fetch(
        "https://us-central1-metatrader-85c07.cloudfunctions.net/getUserByEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            Connection: "keep-alive",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, body: ${errorBody}`
        );
      }
      const result = await response.json();
      return !result.exists;
    } catch (error) {
      console.error("Error checking email availability:", error);
      return false;
    }
  };

  const checkNicknameAvailability = async (nickname) => {
    if (!nickname) {
      console.error("Nickname is required");
      return false;
    }

    const nicknameRef = ref(db, `nickname/${nickname}`);
    const snapshot = await get(nicknameRef);
    const result = snapshot.exists();
    console.log(result);
    return !result;
  };

  const sendVerificationEmail = async (email) => {
    try {
      const response = await fetch(
        "https://us-central1-metatrader-85c07.cloudfunctions.net/sendVerificationEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            Connection: "keep-alive",
          },
          body: JSON.stringify({ email, type: "signup" }),
        }
      );

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, body: ${errorBody}`
        );
      }
      setTimer(180); // 3 minutes countdown
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  const verifyEmailCode = async (code, values) => {
    try {
      const response = await fetch(
        "https://us-central1-metatrader-85c07.cloudfunctions.net/signUp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            Connection: "keep-alive",
          },
          body: JSON.stringify({
            verifyKey: code,
            email: values.email,
            password: values.password,
            firstname: values.firstname,
            lastname: values.lastname,
            phone: values.phone,
            country: values.country,
            referrerUid: values.referralCode,
            nickname: values.nickname,
          }),
        }
      );

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, body: ${errorBody}`
        );
      }
      alert("Registration successful. Redirecting to login page.");
      navigate("/login");
    } catch (error) {
      console.error("Error verifying email code:", error);
    }
  };

  const checkReferralCodeValidity = async (code) => {
    const codeRef = ref(db, `referralCodes/${code}`);
    const snapshot = await get(codeRef);
    const result = snapshot.exists();
    console.log(result);
    return result;
  };

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const handleOpenVerificationDialog = async (values, setErrors) => {
    setLoading(true);
    try {
      const emailAvailable = await checkEmailAvailability(values.email);
      if (!emailAvailable) {
        setErrors({ email: "This Email is already in use." });
        setLoading(false);
        return;
      }

      const nicknameAvailable = await checkNicknameAvailability(
        values.nickname
      );
      if (!nicknameAvailable) {
        setErrors({ nickname: "This Nickname is already in use." });
        setLoading(false);
        return;
      }

      await sendVerificationEmail(values.email);
      setOpenVerificationDialog(true);
    } catch (error) {
      console.error("Error during email verification:", error);
    }
    setLoading(false);
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          confirmPassword: "",
          phone: "",
          country: "",
          referralCode: "",
          verificationCode: "",
          nickname: "",
        }}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().max(255).required("First Name is required"),
          lastname: Yup.string().max(255).required("Last Name is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string()
            .min(8)
            .max(255)
            .required("Password is required"),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm password is required"),
          phone: Yup.string().required("Phone number is required"),
          country: Yup.string().required("Country is required"),
          referralCode: Yup.string(),
          nickname: Yup.string().max(255).required("Nickname is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setStatus({ success: false });
          setSubmitting(true);
          await handleOpenVerificationDialog(values, setErrors);
          setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form className="space-y-6">
            {/* Email Field */}
            <div>
              <label
                htmlFor="email-signup"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address*
              </label>
              <Field
                name="email"
                type="email"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {touched.email && errors.email && (
                <p className="mt-2 text-sm text-red-600">{errors.email}</p>
              )}
              {emailStatus && (
                <p
                  className={`mt-2 text-sm ${
                    emailStatus === "This Email is available."
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {emailStatus}
                </p>
              )}
              <button
                type="button"
                onClick={async () => {
                  setLoading(true);
                  const exist = await checkEmailAvailability(values.email);
                  setEmailStatus(
                    exist
                      ? "This Email is available."
                      : "This Email is already in use."
                  );
                  setLoading(false);
                }}
                className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={loading}
              >
                {loading ? "Checking..." : "Check Availability"}
              </button>
            </div>

            {/* First Name Field */}
            <div>
              <label
                htmlFor="firstname-signup"
                className="block text-sm font-medium text-gray-700"
              >
                First Name*
              </label>
              <Field
                name="firstname"
                type="text"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {touched.firstname && errors.firstname && (
                <p className="mt-2 text-sm text-red-600">{errors.firstname}</p>
              )}
            </div>

            {/* Last Name Field */}
            <div>
              <label
                htmlFor="lastname-signup"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name*
              </label>
              <Field
                name="lastname"
                type="text"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {touched.lastname && errors.lastname && (
                <p className="mt-2 text-sm text-red-600">{errors.lastname}</p>
              )}
            </div>

            {/* Nickname Field */}
            <div>
              <label
                htmlFor="nickname-signup"
                className="block text-sm font-medium text-gray-700"
              >
                Nickname*
              </label>
              <Field
                name="nickname"
                type="text"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {touched.nickname && errors.nickname && (
                <p className="mt-2 text-sm text-red-600">{errors.nickname}</p>
              )}
              {nicknameStatus && (
                <p
                  className={`mt-2 text-sm ${
                    nicknameStatus === "This Nickname is available."
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {nicknameStatus}
                </p>
              )}
              <button
                type="button"
                onClick={async () => {
                  setLoading(true);
                  const available = await checkNicknameAvailability(
                    values.nickname
                  );
                  setNicknameStatus(
                    available
                      ? "This Nickname is available."
                      : "This Nickname is already in use."
                  );
                  setLoading(false);
                }}
                className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={loading}
              >
                {loading ? "Checking..." : "Check Availability"}
              </button>
            </div>

            {/* Phone Number Field */}
            <div>
              <label
                htmlFor="phone-signup"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number*
              </label>
              <Field
                name="phone"
                type="tel"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {touched.phone && errors.phone && (
                <p className="mt-2 text-sm text-red-600">{errors.phone}</p>
              )}
            </div>
            {/* Password Field */}
            <div>
              <label
                htmlFor="password-signup"
                className="block text-sm font-medium text-gray-700"
              >
                Password*
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <Field
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={(e) => {
                    handleChange(e);
                    changePassword(e.target.value);
                  }}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? (
                    <EyeOffIcon className="h-5 w-5 text-gray-400" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
              {touched.password && errors.password && (
                <p className="mt-2 text-sm text-red-600">{errors.password}</p>
              )}
              {passwordStrength && (
                <p
                  className="mt-2 text-sm"
                  style={{ color: passwordStrength.color }}
                >
                  Password Strength: {passwordStrength.label}
                </p>
              )}
            </div>

            {/* Confirm Password Field */}
            <div>
              <label
                htmlFor="confirm-password-signup"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password*
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <Field
                  name="confirmPassword"
                  type={showPasswordConfirm ? "text" : "password"}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={handleClickShowPasswordConfirm}
                >
                  {showPasswordConfirm ? (
                    <EyeOffIcon className="h-5 w-5 text-gray-400" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
              {touched.confirmPassword && errors.confirmPassword && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.confirmPassword}
                </p>
              )}
            </div>

            {/* Country Field */}
            <div>
              <label
                htmlFor="country-signup"
                className="block text-sm font-medium text-gray-700"
              >
                Country*
              </label>
              <Field
                as="select"
                name="country"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select a country</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </Field>
              {touched.country && errors.country && (
                <p className="mt-2 text-sm text-red-600">{errors.country}</p>
              )}
            </div>

            {/* Referral Code Field */}
            <div>
              <label
                htmlFor="referralCode-signup"
                className="block text-sm font-medium text-gray-700"
              >
                Referral Code
              </label>
              <Field
                name="referralCode"
                type="text"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {touched.referralCode && errors.referralCode && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.referralCode}
                </p>
              )}
              <button
                type="button"
                onClick={() =>
                  checkReferralCodeValidity(values.referralCode).then(
                    (valid) => {
                      if (!valid) {
                        alert("This referral code is invalid.");
                        // Reset referral code field
                        setFieldValue("referralCode", "");
                      } else {
                        alert("This referral code is valid.");
                      }
                    }
                  )
                }
                className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Check Code
              </button>
            </div>

            {/* Terms and Conditions */}
            <div className="text-sm">
              By Signing up, you agree to our{" "}
              <Link
                to="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                to="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Privacy Policy
              </Link>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Creating Account..." : "Create Account"}
              </button>
            </div>

            {/* Email Verification Modal */}
            {openVerificationDialog && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Verify Your Email
                  </h3>
                  <div className="mt-2 px-7 py-3">
                    <p className="text-sm text-gray-500">
                      A verification code has been sent to your email. Please
                      enter the verification code.
                    </p>
                    <input
                      type="text"
                      className="mt-3 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Verification Code"
                      value={values.verificationCode}
                      onChange={handleChange}
                      name="verificationCode"
                    />
                    {timer > 0 && (
                      <p className="mt-2 text-sm text-gray-500">
                        You can request a new code in {timer} seconds.
                      </p>
                    )}
                  </div>
                  <div className="flex justify-end mt-3">
                    <button
                      onClick={() => {
                        verifyEmailCode(values.verificationCode, values)
                          .then(() => {
                            alert(
                              "Registration successful. Redirecting to login page."
                            );
                            navigate("/login");
                          })
                          .catch((error) => {
                            console.log(error);
                            // Set error for verification code
                          });
                      }}
                      className="px-4 py-2 bg-indigo-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-300"
                    >
                      Verify
                    </button>
                    <button
                      onClick={() => {
                        if (timer <= 0) {
                          sendVerificationEmail(values.email);
                        }
                      }}
                      className="ml-3 px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                      disabled={timer > 0}
                    >
                      Request New Code
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AuthRegister;
