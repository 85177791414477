import React from "react";
import aboutImage from "../assets/about.png";

const ContactCard = () => (
  <div className="bg-green-500 p-6 md:p-8 rounded-3xl flex flex-col justify-between h-full relative overflow-hidden">
    {/* 이미지 컨테이너 */}
    <div className="absolute inset-0 z-0">
      <img
        src={aboutImage}
        alt="About us"
        className="w-full h-full object-cover"
        style={{ objectPosition: "50% 50%" }}
      />
    </div>

    {/* 반투명 오버레이 */}
    <div className="absolute inset-0 bg-green-500 opacity-50 z-10"></div>

    {/* 데스크톱 버전 */}
    <div className="hidden lg:block z-20 relative">
      <p className="text-xl md:text-2xl text-white">About us</p>
    </div>

    {/* 모바일 버전 */}
    <div className="lg:hidden flex items-center justify-center h-full z-20 relative">
      <h2 className="text-4xl font-bold text-white">ABOUT US</h2>
    </div>
  </div>
);

export default ContactCard;
