import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../config/firebaseConfig";
import { ref, get } from "firebase/database";
import ReactApexChart from "react-apexcharts";
import { format, subDays } from "date-fns";

// chart options
const areaChartOptions = {
  chart: {
    height: 340,
    type: "line",
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 1.5,
  },
  grid: {
    strokeDashArray: 4,
  },
  xaxis: {
    type: "datetime",
    labels: {
      format: "MMM dd",
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    x: {
      format: "MM/dd",
    },
  },
};

const formatDrawdownDate = (date) => {
  const [day, time] = date.split("/");
  const formattedDay = format(
    new Date(day.replace(/_/g, "-")),
    "yyyy년 MM월 dd일"
  );
  const formattedTime = time.replace(/_/g, ":");
  return `${formattedDay} ${formattedTime}분`;
};

const ReportAreaChart = () => {
  const { nodeId } = useParams();

  const [options, setOptions] = useState(areaChartOptions);
  const [series, setSeries] = useState([]);
  const [maxDrawdown, setMaxDrawdown] = useState(0);
  const [avgMaxDrawdown, setAvgMaxDrawdown] = useState(0);
  const [maxDrawdownDate, setMaxDrawdownDate] = useState("");

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: ["#f59e0b"], // Tailwind's yellow-500
      xaxis: {
        labels: {
          style: {
            colors: Array(7).fill("#6b7280"), // Tailwind's gray-500
          },
        },
      },
      grid: {
        borderColor: "#e5e7eb", // Tailwind's gray-200
      },
      tooltip: {
        theme: "light",
      },
      legend: {
        labels: {
          colors: "#6b7280", // Tailwind's gray-500
        },
      },
    }));
  }, []);

  useEffect(() => {
    const fetchMaxDrawdowns = async () => {
      try {
        const mddRef = ref(db, `mt4data/${nodeId}/MDD`);
        const snapshot = await get(mddRef);
        if (snapshot.exists()) {
          const data = snapshot.val();

          const dates = Array.from({ length: 7 }, (_, i) =>
            subDays(new Date(), i)
          ).reverse();
          const formattedDates = dates.map((date) =>
            format(date, "yyyy_MM_dd")
          );

          let totalDrawdown = 0;
          let drawdownCount = 0;
          let overallMaxDrawdown = 0;
          let overallMaxDrawdownDate = "";

          const maxDrawdowns = formattedDates.map((date) => {
            const dailyData = data[date];
            if (dailyData) {
              let dailyMaxDrawdown = 0;
              for (const time in dailyData) {
                const balance = parseFloat(dailyData[time].Balance);
                const equity = parseFloat(dailyData[time].Equity);
                const drawdown = ((balance - equity) / balance) * 100;
                if (drawdown > dailyMaxDrawdown) {
                  dailyMaxDrawdown = drawdown;
                  if (drawdown > overallMaxDrawdown) {
                    overallMaxDrawdown = drawdown;
                    overallMaxDrawdownDate = `${date}/${time}`;
                  }
                }
              }
              totalDrawdown += dailyMaxDrawdown;
              drawdownCount += 1;
              return parseFloat(dailyMaxDrawdown.toFixed(2));
            }
            return 0;
          });

          setMaxDrawdown(parseFloat(Math.max(...maxDrawdowns).toFixed(2)));
          setAvgMaxDrawdown(
            parseFloat((totalDrawdown / drawdownCount).toFixed(2))
          );
          setMaxDrawdownDate(overallMaxDrawdownDate);

          setSeries([{ name: "Max Drawdown", data: maxDrawdowns }]);
          setOptions((prevState) => ({
            ...prevState,
            xaxis: {
              ...prevState.xaxis,
              categories: dates.map((date) => date.toISOString()),
            },
          }));
        }
      } catch (error) {
        console.error("Error fetching max drawdowns:", error);
      }
    };

    if (nodeId) {
      fetchMaxDrawdowns();
    }
  }, [nodeId]);

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <ul className="divide-y divide-gray-200">
        <li className="py-4">
          <div className="flex justify-between">
            <span className="text-gray-600">최대하락폭</span>
            <span className="font-semibold">{maxDrawdown.toFixed(2)}%</span>
          </div>
        </li>
        <li className="py-4">
          <div className="flex justify-between">
            <span className="text-gray-600">계좌 평균 최대MDD</span>
            <span className="font-semibold">{avgMaxDrawdown.toFixed(2)}%</span>
          </div>
        </li>
        <li className="py-4">
          <div className="flex justify-between">
            <span className="text-gray-600">최대하락 발생일</span>
            <span className="font-semibold">
              {maxDrawdownDate ? formatDrawdownDate(maxDrawdownDate) : "N/A"}
            </span>
          </div>
        </li>
      </ul>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={345}
      />
    </div>
  );
};

export default ReportAreaChart;
