import { useEffect, useState } from "react";
import { db } from "../../../config/firebaseConfig";
import { ref, get } from "firebase/database";
import { useParams } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {
  subDays,
  subWeeks,
  subMonths,
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  isValid,
} from "date-fns";

const barChartOptions = {
  chart: {
    type: "bar",
    height: 365,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "45%",
      borderRadius: 4,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  grid: {
    show: false,
  },
  tooltip: {
    x: {
      formatter: (val, opts) => {
        const date = opts.w.globals.labels[opts.dataPointIndex];
        const [start, end] = date.split(" - ");

        if (opts.w.config.xaxis.categories[0].length === 2) {
          // Handling day format
          const day = new Date(opts.w.globals.seriesX[0][opts.dataPointIndex]);
          return `${format(day, "MM월dd일")}(${format(day, "eee")})`;
        } else {
          // Handling other formats
          const startDate = new Date(start.replace("/", "-"));
          const endDate = end ? new Date(end.replace("/", "-")) : startDate;

          if (isValid(endDate)) {
            return `${format(endDate, "MM월dd일")}(${format(endDate, "eee")})`;
          } else if (isValid(startDate)) {
            return `${format(startDate, "MM월dd일")}(${format(
              startDate,
              "eee"
            )})`;
          } else {
            return "Invalid date";
          }
        }
      },
    },
    y: {
      formatter: (val) => `$${val.toFixed(2)}`,
    },
  },
};

const getFormattedDates = (unit, availableYears, monthRange) => {
  const now = new Date();
  let dates = [];

  if (unit === "day") {
    dates = Array.from({ length: 7 }, (_, i) => subDays(now, i)).reverse();
  } else if (unit === "week") {
    dates = Array.from({ length: 5 }, (_, i) => subWeeks(now, i)).reverse();
  } else if (unit === "month") {
    dates = Array.from({ length: monthRange }, (_, i) =>
      subMonths(now, i)
    ).reverse();
  } else if (unit === "year") {
    dates = availableYears.map((year) => new Date(year, 0, 1));
  }

  return dates;
};

const formatDatesForDisplay = (dates, unit) => {
  if (unit === "day") {
    return dates.map((date) => format(date, "EEE"));
  } else if (unit === "week") {
    return dates.map((date) => {
      const start = startOfWeek(date, { weekStartsOn: 1 });
      const end = endOfWeek(date, { weekStartsOn: 1 });
      return `${format(start, "MM/dd")} - ${format(end, "MM/dd")}`;
    });
  } else if (unit === "month") {
    return dates.map((date) => {
      const start = startOfMonth(date);
      const end = endOfMonth(date);
      return `${format(start, "MM/dd")} - ${format(end, "MM/dd")}`;
    });
  } else if (unit === "year") {
    return dates.map((date) => {
      const start = startOfYear(date);
      const end = endOfYear(date);
      return `${format(start, "yyyy/MM/dd")} - ${format(end, "yyyy/MM/dd")}`;
    });
  }
};

const MonthlyBarChart = () => {
  const { nodeId } = useParams();

  const [series, setSeries] = useState([
    { data: [0, 0, 0, 0, 0, 0, 0], name: "Profit" },
  ]);
  const [options, setOptions] = useState(barChartOptions);
  const [timeUnit, setTimeUnit] = useState("week");
  const [totalProfit, setTotalProfit] = useState(0);
  const [monthRange, setMonthRange] = useState(3);
  const [showMonthMenu, setShowMonthMenu] = useState(false);

  const fetchData = async () => {
    try {
      const dataRef = ref(db, `mt4data/${nodeId}/analysis/DailyProfit`);
      const snapshot = await get(dataRef);
      if (snapshot.exists()) {
        const data = snapshot.val();

        const availableYears = Object.keys(data).map(
          (date) => date.split("_")[0]
        );
        const uniqueYears = [...new Set(availableYears)];

        const dates = getFormattedDates(timeUnit, uniqueYears, monthRange);
        const formattedDates = dates.map((date) => format(date, "yyyy_MM_dd"));

        const periodData = formattedDates.map((date) => {
          if (timeUnit === "day") {
            return data[date] || 0;
          } else if (timeUnit === "week") {
            const start = startOfWeek(new Date(date.replace(/_/g, "-")), {
              weekStartsOn: 1,
            });
            const end = endOfWeek(new Date(date.replace(/_/g, "-")), {
              weekStartsOn: 1,
            });
            let sum = 0;
            for (let d = start; d <= end; d = subDays(d, -1)) {
              sum += data[format(d, "yyyy_MM_dd")] || 0;
            }
            return sum;
          } else if (timeUnit === "month") {
            const start = startOfMonth(new Date(date.replace(/_/g, "-")));
            const end = endOfMonth(new Date(date.replace(/_/g, "-")));
            let sum = 0;
            for (let d = start; d <= end; d = subDays(d, -1)) {
              sum += data[format(d, "yyyy_MM_dd")] || 0;
            }
            return sum;
          } else if (timeUnit === "year") {
            const start = startOfYear(new Date(date.replace(/_/g, "-")));
            const end = endOfYear(new Date(date.replace(/_/g, "-")));
            let sum = 0;
            for (let d = start; d <= end; d = subDays(d, -1)) {
              sum += data[format(d, "yyyy_MM_dd")] || 0;
            }
            return sum;
          }
          return 0;
        });

        const periodCategories = formatDatesForDisplay(dates, timeUnit);

        setSeries([
          {
            data: periodData.map((num) => Math.round(num * 1000) / 1000),
            name: "Profit",
          },
        ]);
        setOptions((prevState) => ({
          ...prevState,
          xaxis: {
            categories: periodCategories,
            labels: {
              style: {
                colors: periodCategories.map(() => "#666"),
              },
            },
          },
          tooltip: {
            x: {
              formatter: (val, opts) => {
                const date = opts.w.globals.labels[opts.dataPointIndex];
                if (timeUnit === "day") {
                  const dayIndex = opts.dataPointIndex;
                  const dayDate = subDays(new Date(), 6 - dayIndex);
                  return `${format(dayDate, "MM월dd일")}(${format(
                    dayDate,
                    "eee"
                  )})`;
                } else {
                  const [start, end] = date.split(" - ");
                  const startDate = new Date(start.replace("/", "-"));
                  const endDate = end
                    ? new Date(end.replace("/", "-"))
                    : startDate;

                  if (isValid(endDate)) {
                    return `${format(endDate, "MM월dd일")}(${format(
                      endDate,
                      "eee"
                    )})`;
                  } else if (isValid(startDate)) {
                    return `${format(startDate, "MM월dd일")}(${format(
                      startDate,
                      "eee"
                    )})`;
                  } else {
                    return "Invalid date";
                  }
                }
              },
            },
            y: {
              formatter: (val) => `$${val.toFixed(2)}`,
            },
          },
        }));

        setTotalProfit(periodData.reduce((acc, val) => acc + val, 0));
      }
    } catch (error) {
      console.error("Error fetching daily profits:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [nodeId, timeUnit, monthRange]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: ["#60a5fa"],
      tooltip: {
        theme: "light",
      },
    }));
  }, []);

  const handleMonthRangeSelect = (range) => {
    setMonthRange(range);
    setShowMonthMenu(false);
  };

  return (
    <div id="chart" className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl font-bold">
          ${(Math.round(totalProfit * 1000) / 1000).toFixed(2)}
        </h3>
        <div className="flex space-x-2">
          <button
            onClick={() => setTimeUnit("day")}
            className={`px-3 py-1 rounded ${
              timeUnit === "day"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            일
          </button>
          <button
            onClick={() => setTimeUnit("week")}
            className={`px-3 py-1 rounded ${
              timeUnit === "week"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            주
          </button>
          <div className="relative">
            <button
              onClick={() => {
                setTimeUnit("month");
                setShowMonthMenu(!showMonthMenu);
              }}
              className={`px-3 py-1 rounded ${
                timeUnit === "month"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              월
            </button>
            {showMonthMenu && (
              <div className="absolute right-0 mt-2 py-2 w-32 bg-white rounded-md shadow-xl z-20">
                <button
                  onClick={() => handleMonthRangeSelect(3)}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                >
                  3개월
                </button>
                <button
                  onClick={() => handleMonthRangeSelect(6)}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                >
                  6개월
                </button>
                <button
                  onClick={() => handleMonthRangeSelect(12)}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                >
                  12개월
                </button>
              </div>
            )}
          </div>
          <button
            onClick={() => setTimeUnit("year")}
            className={`px-3 py-1 rounded ${
              timeUnit === "year"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            년
          </button>
        </div>
      </div>
      <h6 className="text-lg font-semibold mb-4">
        This{" "}
        {timeUnit === "day"
          ? "Week"
          : timeUnit === "week"
          ? "Month"
          : timeUnit === "month"
          ? "Quarter"
          : "Year"}{" "}
        Statistics
      </h6>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={365}
      />
    </div>
  );
};

export default MonthlyBarChart;
