import React from "react";
import UserLeaderboard from "./UserLeaderboard.jsx";

const AccountList = () => {
  return (
    <div className="mb-6">
      <UserLeaderboard />
    </div>
  );
};

export default AccountList;
