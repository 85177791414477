import React from "react";
import { ArrowUpRight } from "lucide-react";
import blogImage from "../assets/blog.png";

const BlogCard = () => (
  <div className="bg-gray-100 text-black p-6 md:p-8 rounded-3xl flex flex-col justify-between h-full relative overflow-hidden">
    {/* 배경 이미지 컨테이너 */}
    <div
      className="absolute inset-0 bg-cover bg-center z-0"
      style={{
        backgroundImage: `url(${blogImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "brightness(1.1)",
      }}
    ></div>

    {/* 반투명 오버레이, 연하게 조정 */}
    <div className="absolute inset-0 bg-gray-100 opacity-30 z-10"></div>

    {/* 콘텐츠 */}
    <div className="relative z-20 flex flex-col justify-between h-full">
      {/* 데스크톱 버전 */}
      <div className="hidden lg:flex lg:flex-col lg:justify-between lg:h-full">
        <div className="flex justify-between items-start">
          <h3 className="text-xs md:text-sm">WORLD OF HEADING TECHNOLOGY</h3>
          <ArrowUpRight className="w-4 h-4 md:w-5 md:h-5" />
        </div>

        <div className="mt-4 md:mt-8 flex items-end justify-between">
          <p className="text-2xl md:text-5xl font-bold">
            The fastest financial news in the world
          </p>
        </div>
      </div>

      {/* 모바일 버전 */}
      <div className="lg:hidden flex items-center justify-center h-full">
        <h2 className="text-4xl font-bold">Financial News</h2>
      </div>
    </div>
  </div>
);

export default BlogCard;
