const Banner = () => (
  <div className="flex gap-4 mb-6">
    <div className="bg-white p-6 rounded-lg shadow-md flex-1">
      <h2 className="text-4xl font-bold">FX</h2>
      <p>ALL THAT FX</p>
    </div>
    <div className="bg-white p-6 rounded-lg shadow-md flex-1">
      <h2 className="text-4xl font-bold">FX</h2>
      <p>YOUR BEST FX MANAGER</p>
    </div>
  </div>
);
export default Banner;
