import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "../../../config/firebaseConfig";
import { ref, onValue, off } from "firebase/database";

// Table Head 구성
const headCells = [
  { id: "trackingNo", align: "left", label: "Ticket." },
  { id: "name", align: "left", label: "Currency" },
  { id: "totalOrder", align: "right", label: "lot" },
  { id: "status", align: "left", label: "Status" },
  { id: "totalAmount", align: "right", label: "P/L" },
  { id: "comment", align: "left", label: "Comment" },
];

function OrderTableHead() {
  return (
    <thead>
      <tr>
        {headCells.map((headCell) => (
          <th
            key={headCell.id}
            className={`px-6 py-3 text-${headCell.align} text-xs font-medium text-gray-500 uppercase tracking-wider`}
          >
            {headCell.label}
          </th>
        ))}
      </tr>
    </thead>
  );
}

// Status 컴포넌트
const OrderStatus = ({ profit }) => {
  const color =
    profit > 0
      ? "text-green-600"
      : profit < 0
      ? "text-red-600"
      : "text-yellow-600";
  const title = profit > 0 ? "Profit" : profit < 0 ? "Loss" : "Breakeven";

  return (
    <div className="flex items-center">
      <span className={`w-2 h-2 rounded-full ${color} mr-2`}></span>
      <span>{title}</span>
    </div>
  );
};

// 숫자 포맷팅 함수
const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export default function OrderTable() {
  const { nodeId } = useParams();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!nodeId) return;

    const tradesRef = ref(db, `mt4data/${nodeId}/opentrade`);

    const onValueChange = onValue(tradesRef, (snapshot) => {
      if (snapshot.exists()) {
        const tradesData = snapshot.val();
        const latestTimestamp = Math.max(...Object.keys(tradesData));
        const latestTrades = tradesData[latestTimestamp];
        let tradeRows = Object.keys(latestTrades).map((key) => ({
          trackingNo: latestTrades[key]["Ticket"],
          name: latestTrades[key]["Item"],
          totalOrder: latestTrades[key]["Size"],
          status: latestTrades[key]["Status"],
          totalAmount: latestTrades[key]["Profit"],
          comment: latestTrades[key]["Comment"] || "",
        }));

        tradeRows = tradeRows.slice(0, -2);
        setRows(tradeRows);
      } else {
        setRows([]);
      }
    });

    return () => off(tradesRef, "value", onValueChange);
  }, [nodeId]);

  return (
    <div className="overflow-x-auto">
      <div className="align-middle inline-block min-w-full">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <OrderTableHead />
            <tbody className="bg-white divide-y divide-gray-200">
              {rows.map((row, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <Link
                      to={`/details/${row.trackingNo}`}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      {row.trackingNo}
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    {row.totalOrder}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <OrderStatus profit={parseFloat(row.totalAmount)} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    {formatCurrency(row.totalAmount)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.comment}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
