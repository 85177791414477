import React, { useState, useEffect } from "react";
import { newsDb } from "../config/firebaseNewsConfig";
import { ref, query, orderByKey, limitToLast, get } from "firebase/database";
import Timeline from "../components/news/Timeline";
import TradingViewWidget from "../components/news/TradingViewWidget"; // 트레이딩뷰 위젯

const NewsPage = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const newsPerPage = 5;

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const newsRef = ref(newsDb, "save/allthatfx/macro");
        const newsQuery = query(newsRef, orderByKey(), limitToLast(100)); // 최근 100개 항목 가져오기
        const snapshot = await get(newsQuery);

        if (snapshot.exists()) {
          const fetchedNews = [];
          snapshot.forEach((childSnapshot) => {
            const timestamp = childSnapshot.key;
            const data = childSnapshot.val();
            if (data.title && data.content) {
              fetchedNews.push({
                id: timestamp,
                time: new Date(parseInt(timestamp)).toLocaleString(),
                title: data.title,
                content: data.content,
              });
            }
          });

          // 시간 순으로 정렬 (최신 순)
          fetchedNews.sort((a, b) => b.id - a.id);

          setNewsData(fetchedNews);
          setTotalPages(Math.ceil(fetchedNews.length / newsPerPage));
        } else {
          setNewsData([]);
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching news: ", err);
        setError("Failed to load news. Please try again later.");
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const getCurrentPageData = () => {
    const indexOfLastNews = currentPage * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;
    return newsData.slice(indexOfFirstNews, indexOfLastNews);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex">
      {/* 뉴스 피드 */}
      <div className="news-page max-w-3xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md mt-8 flex-1">
        <h1 className="text-3xl font-bold mb-4 text-center">Latest News</h1>
        {newsData.length > 0 ? (
          <>
            <Timeline data={getCurrentPageData()} />
            <div className="flex justify-center mt-4">
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`mx-1 px-3 py-1 rounded ${
                    currentPage === i + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </>
        ) : (
          <p>No news available.</p>
        )}
      </div>

      {/* 트레이딩뷰 위젯 사이드바 */}
      <div className="ml-8">
        <TradingViewWidget />
      </div>
    </div>
  );
};

export default NewsPage;
