import React, { useState } from "react";

const AnalysisReport = () => {
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");

  const handleAnalyze = async () => {
    try {
      const res = await fetch("http://localhost:3001/analyze", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt }),
      });

      const data = await res.json();
      setResponse(data.response);
    } catch (error) {
      console.error(error);
      setResponse("Error occurred while analyzing.");
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Analyze Account</h2>
      <textarea
        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter your prompt here"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        rows={4}
      />
      <button
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        onClick={handleAnalyze}
      >
        Analyze
      </button>
      <div className="mt-4">
        <h3 className="text-xl font-semibold">Analysis Report</h3>
        <pre className="mt-2 p-4 bg-gray-100 rounded-md whitespace-pre-wrap">
          {response}
        </pre>
      </div>
    </div>
  );
};

export default AnalysisReport;
