import React, { useState } from "react";
import { db, auth } from "../../config/firebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";
import { ref, get } from "firebase/database";

const PasswordResetModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openEmailRecovery, setOpenEmailRecovery] = useState(false);

  const handleResetPassword = async () => {
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Please check your inbox.");
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const toggleEmailRecoveryModal = () => {
    setOpenEmailRecovery(!openEmailRecovery);
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Reset Your Password
          </h3>
          <div className="mt-2 px-7 py-3">
            {message ? (
              <p className="text-sm text-gray-500">{message}</p>
            ) : (
              <>
                <p className="text-sm text-gray-500">
                  Enter your email address and we'll send you a link to reset
                  your password.
                </p>
                <input
                  type="email"
                  className="mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                <button
                  onClick={toggleEmailRecoveryModal}
                  className="mt-2 text-sm text-blue-600 hover:underline"
                >
                  Forgot email?
                </button>
              </>
            )}
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Close
            </button>
            {!message && (
              <button
                onClick={handleResetPassword}
                disabled={loading}
                className="mt-3 px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                Send Reset Link
              </button>
            )}
          </div>
        </div>
      </div>
      {openEmailRecovery && (
        <EmailRecoveryModal
          isOpen={openEmailRecovery}
          onClose={toggleEmailRecoveryModal}
        />
      )}
    </div>
  );
};

const EmailRecoveryModal = ({ isOpen, onClose }) => {
  const [userID, setUserID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [isFound, setIsFound] = useState(false);

  const handleRecoverEmail = async () => {
    try {
      const userRef = ref(db, `users/${userID}`);
      const snapshot = await get(userRef);
      if (
        snapshot.exists() &&
        snapshot.val().firstName === firstName &&
        snapshot.val().lastName === lastName
      ) {
        setEmail(snapshot.val().email);
        setIsFound(true);
      } else {
        setError("No matching records found.");
        setIsFound(false);
      }
    } catch (e) {
      setError("Failed to connect to the database.");
      setIsFound(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Recover Your Email
        </h3>
        <div className="mt-2 px-7 py-3">
          {isFound ? (
            <p className="text-lg font-medium text-gray-900">{email}</p>
          ) : (
            <>
              <input
                type="text"
                className="mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="User ID"
                value={userID}
                onChange={(e) => setUserID(e.target.value)}
              />
              <input
                type="text"
                className="mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                type="text"
                className="mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
            </>
          )}
        </div>
        <div className="items-center px-4 py-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
          >
            Close
          </button>
          {!isFound && (
            <button
              onClick={handleRecoverEmail}
              className="mt-3 px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Recover Email
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordResetModal;
